/**
 *
 * ReverseCurve
 *
 */

import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function ReverseCurve() {
  return (
    <div className="curve curve--blue">
      <svg
        className="hide-phone"
        width="100%"
        height="77px"
        viewBox="0 0 1400 77"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M.218.713C135.056 47.243 397.87 76.638 700 76.638c302.13 0 564.944-29.396 699.782-75.925h.218v77H0v-77h.218z"
          fill="#8DD1D8"
          fillRule="evenodd"
        />
      </svg>
      <svg
        className="phone-only"
        width="100%"
        height="36px"
        viewBox="0 0 375 36"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M188.49 35.667C107.943 35.667 35.916 21.23 0 0v36h375V0c-35.917 21.231-105.965 35.667-186.51 35.667z"
          fill="#8DD1D8"
          fillRule="nonzero"
        />
      </svg>
    </div>
  );
}

ReverseCurve.propTypes = {};

export default ReverseCurve;
