/* eslint-disable react/prop-types */
import { isEmpty } from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { makeSelectUser } from 'containers/User/selectors';
import LoadingScreen from '../../components/LoadingIndicator';
import { fetchStatus } from '../Appointment/actions';
import makeSelectAppointment from '../Appointment/selectors';
import makeSelectMembership from '../Membership/selectors';

class EligibleRoute extends React.PureComponent {
  render() {
    const {
      // eslint-disable-next-line no-shadow
      component: Component,
      user: { fetching, userData },
      location,
      appointmentData: { myStatus, fetchingStatus },
      // membershipData: { membershipDetails },
      ...rest
    } = this.props; // eslint-disable-line react/prop-types
    const hasPendingActions =
      !fetchingStatus && myStatus && myStatus.pendingAppointmentActions;

    const untippedAppointments =
      hasPendingActions &&
      hasPendingActions.filter(
        i => (Number(i.hasTip) === 0 && !isEmpty(i.therapist) ? i : null),
      );

    const unReviewedAppointments =
      hasPendingActions &&
      hasPendingActions.filter(
        i => (Number(i.hasReview) === 0 && !isEmpty(i.therapist) ? i : null),
      );

    const paymentFailedAppointments =
      !fetchingStatus && myStatus && myStatus.hasFailedAppointments;

    const hasMembershipPaymentFailed =
      !fetchingStatus && myStatus && myStatus.hasFailedMembership;

    const requireLiabilityForm =
      !fetchingStatus && myStatus && myStatus.requireLiabilityForm;

    const requirePreferences =
      !fetchingStatus && myStatus && myStatus.requirePreferences;

    if (fetchingStatus) {
      return <LoadingScreen hangTight="true" />;
    } else if (
      (!fetching && !userData.id) ||
      (!isEmpty(userData) &&
        isEmpty(untippedAppointments) &&
        !paymentFailedAppointments &&
        isEmpty(unReviewedAppointments) &&
        isEmpty(hasMembershipPaymentFailed) &&
        !requireLiabilityForm &&
        !requirePreferences)
    ) {
      return <Component {...rest} />;
    }

    let redirectPath;
    if (paymentFailedAppointments) {
      redirectPath = '/appointment/payment-failed';
    } else if (!isEmpty(hasMembershipPaymentFailed)) {
      redirectPath = '/membership/payment-failed';
    } else if (!isEmpty(untippedAppointments)) {
      redirectPath = '/gratuity/tip';
    } else if (!isEmpty(unReviewedAppointments)) {
      redirectPath = '/gratuity/rate';
    } else if (requireLiabilityForm || requirePreferences) {
      redirectPath = '/appointment/booked';
    }

    return (
      <Redirect
        to={{
          pathname: redirectPath,
          search: location.search,
          state: { from: this.props.location }, // eslint-disable-line react/prop-types
        }}
      />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
  appointmentData: makeSelectAppointment(),
  membershipData: makeSelectMembership(),
});

const withConnect = connect(
  mapStateToProps,
  { fetchStatus },
);

export default compose(
  withConnect,
  withRouter,
)(EligibleRoute);
