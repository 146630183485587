/*
 *
 * Appointment actions
 *
 */

import {
  APPLY_REFERRAL,
  APPLY_REFERRAL_SUCCESS,
  CANCEL_USER_APPOINTMENT,
  CANCEL_USER_APPOINTMENT_FAILURE,
  CANCEL_USER_APPOINTMENT_SUCCESS,
  CHECK_GIFT_CODE,
  CHECK_GIFT_CODE_FAILURE,
  CHECK_GIFT_CODE_SUCCESS,
  CLEAR_APPOINTMENT_BOOKING_DATA,
  CLEAR_APPOINTMENT_NET_VALUE,
  CLEAR_CURRENT_SELECTED_DATE,
  CLEAR_CURRENT_THERAPIST_CLICKED,
  CLEAR_ENTERED_PHONE_NUMBER,
  CLEAR_ENTERED_PROMO_CODE,
  CLEAR_FILTER_STATE,
  CLEAR_GIFT_CODE,
  CLEAR_NEAREST_LOCATIONS,
  CLEAR_SELECTED_APPOINTMENT_SLOT,
  CLEAR_SELECTED_LOCATION,
  CLEAR_SELECTED_MASSAGE_TYPE,
  CLEAR_SELECTED_SERVICE,
  CREATE_USER_APPOINTMENT,
  CREATE_USER_APPOINTMENT_FAILURE,
  CREATE_USER_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_AVAILABLE_THERAPISTS,
  FETCH_AVAILABLE_THERAPISTS_FAILURE,
  FETCH_AVAILABLE_THERAPISTS_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATION_MORE_INFO,
  FETCH_LOCATION_MORE_INFO_FAILURE,
  FETCH_LOCATION_MORE_INFO_SUCCESS,
  FETCH_LOCATION_SERVICES,
  FETCH_LOCATION_SERVICES_FAILURE,
  FETCH_LOCATION_SERVICES_SUCCESS,
  FETCH_MASSAGE_TYPES,
  FETCH_MASSAGE_TYPES_FAILURE,
  FETCH_MASSAGE_TYPES_SUCCESS,
  FETCH_NEAREST_LOCATION,
  FETCH_NEAREST_LOCATION_FAILURE,
  FETCH_NEAREST_LOCATION_SUCCESS,
  FETCH_STATUS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_SUCCESS,
  FETCH_USER_COMPLETED_APPOINTMENTS,
  FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE,
  FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS,
  FETCH_USER_PRESSURE,
  FETCH_USER_PRESSURE_FAILURE,
  FETCH_USER_PRESSURE_SUCCESS,
  GET_LIABILITY_WAIVER,
  GET_LIABILITY_WAIVER_FAILURE,
  GET_LIABILITY_WAIVER_SUCCESS,
  RESERVE_APPOINTMENT_SAGA,
  RETRY_APPOINTMENT_CHARGE,
  RETRY_APPOINTMENT_CHARGE_FAILURE,
  RETRY_APPOINTMENT_CHARGE_SUCCESS,
  SEND_SIGNATURE,
  SEND_SIGNATURE_FAILURE,
  SEND_SIGNATURE_SUCCESS,
  SET_APPOINTMENT_NET_VALUE,
  SET_BOOKING_FLOW,
  SET_CURRENT_SELECTED_DATE,
  SET_CURRENT_THERAPIST_CLICKED,
  SET_ENTERED_PHONE_NUMBER,
  SET_ENTERED_PROMO_CODE,
  SET_FILTER_STATE,
  SET_NOT_AVAILABILE_TILL_DATE,
  SET_SELECTED_APPOINTMENT_SLOT,
  SET_SELECTED_LOCATION,
  SET_SELECTED_MASSAGE_TYPE,
  SET_SELECTED_SERVICE,
  UPDATE_APPOINTMENT_TEXT,
  UPDATE_APPOINTMENT_TEXT_ERROR,
  UPDATE_APPOINTMENT_TEXT_SUCCESS,
  UPDATE_USER_PRESSURE,
  UPDATE_USER_PRESSURE_FAILURE,
  UPDATE_USER_PRESSURE_SUCCESS,
} from './constants';

export function fetchLocations() {
  return {
    type: FETCH_LOCATIONS,
  };
}

export function fetchLocationsSuccess(result) {
  return {
    type: FETCH_LOCATIONS_SUCCESS,
    result,
  };
}

export function fetchLocationsFailure(error) {
  return {
    type: FETCH_LOCATIONS_FAILURE,
    error,
  };
}

export function setSelectedLocation(location) {
  return {
    type: SET_SELECTED_LOCATION,
    location,
  };
}

export function fetchLocationServices(locationId, isAuth) {
  return {
    type: FETCH_LOCATION_SERVICES,
    locationId,
    isAuth,
  };
}

export function fetchLocationServicesSuccess(result) {
  return {
    type: FETCH_LOCATION_SERVICES_SUCCESS,
    result,
  };
}

export function fetchLocationServicesFailure(error) {
  return {
    type: FETCH_LOCATION_SERVICES_FAILURE,
    error,
  };
}

export function fetchLocationMoreInfo(locationId) {
  return {
    type: FETCH_LOCATION_MORE_INFO,
    locationId,
  };
}

export function fetchLocationMoreInfoSuccess(result) {
  return {
    type: FETCH_LOCATION_MORE_INFO_SUCCESS,
    result,
  };
}

export function fetchLocationMoreInfoFailure(error) {
  return {
    type: FETCH_LOCATION_MORE_INFO_FAILURE,
    error,
  };
}

export function setSelectedService(service) {
  return {
    type: SET_SELECTED_SERVICE,
    service,
  };
}

export function fetchMassageTypes() {
  return {
    type: FETCH_MASSAGE_TYPES,
  };
}

export function fetchMassageTypesSuccess(result) {
  return {
    type: FETCH_MASSAGE_TYPES_SUCCESS,
    result,
  };
}

export function fetchMassageTypesFailure(error) {
  return {
    type: FETCH_MASSAGE_TYPES_FAILURE,
    error,
  };
}

export function setSelectedMassageType(massageType) {
  return {
    type: SET_SELECTED_MASSAGE_TYPE,
    massageType,
  };
}

export function fetchAvailableTherapists({
  start_date_time,
  end_date_time,
  service_id,
  location_id,
  massage_type_id,
  pregnancy,
  therapist_id,
  nextAvailability,
  pre_live,
}) {
  return {
    type: FETCH_AVAILABLE_THERAPISTS,
    start_date_time,
    end_date_time,
    service_id,
    location_id,
    massage_type_id,
    pregnancy,
    therapist_id,
    nextAvailability,
    pre_live,
  };
}

export function fetchAvailableTherapistsSuccess(result) {
  return {
    type: FETCH_AVAILABLE_THERAPISTS_SUCCESS,
    result,
  };
}

export function setNotAvailableTillDate(result) {
  return { type: SET_NOT_AVAILABILE_TILL_DATE, result };
}

export function fetchAvailableTherapistsFailure(error) {
  return {
    type: FETCH_AVAILABLE_THERAPISTS_FAILURE,
    error,
  };
}

export function setSelectedAppointmentSlot(appointmentSlot) {
  return {
    type: SET_SELECTED_APPOINTMENT_SLOT,
    appointmentSlot,
  };
}

export function fetchUserPressure() {
  return {
    type: FETCH_USER_PRESSURE,
  };
}

export function fetchUserPressureSuccess(result) {
  return {
    type: FETCH_USER_PRESSURE_SUCCESS,
    result,
  };
}

export function fetchUserPressureFailure(error) {
  return {
    type: FETCH_USER_PRESSURE_FAILURE,
    error,
  };
}

export function updateUserPressure({
  customer_pressure,
  bun_in_the_oven,
  is_first_trimester,
}) {
  return {
    type: UPDATE_USER_PRESSURE,
    customer_pressure,
    bun_in_the_oven,
    is_first_trimester,
  };
}

export function updateUserPressureSuccess(result) {
  return {
    type: UPDATE_USER_PRESSURE_SUCCESS,
    result,
  };
}

export function updateUserPressureFailure(error) {
  return {
    type: UPDATE_USER_PRESSURE_FAILURE,
    error,
  };
}

export function checkGiftCode(details) {
  return {
    type: CHECK_GIFT_CODE,
    ...details,
  };
}

export function checkGiftCodeSuccess(result) {
  return {
    type: CHECK_GIFT_CODE_SUCCESS,
    result,
  };
}

export function checkGiftCodeFailure(error) {
  return {
    type: CHECK_GIFT_CODE_FAILURE,
    error,
  };
}

export function clearGiftCode() {
  return {
    type: CLEAR_GIFT_CODE,
  };
}

export function createUserAppointment(appointmentObj) {
  return {
    type: CREATE_USER_APPOINTMENT,
    appointmentObj,
  };
}

export function createUserAppointmentSuccess(result) {
  return {
    type: CREATE_USER_APPOINTMENT_SUCCESS,
    result,
  };
}

export function createUserAppointmentFailure(error) {
  return {
    type: CREATE_USER_APPOINTMENT_FAILURE,
    error,
  };
}

export function clearSelectedAppointmentSlot() {
  return {
    type: CLEAR_SELECTED_APPOINTMENT_SLOT,
  };
}

export function clearSelectedMassageType() {
  return {
    type: CLEAR_SELECTED_MASSAGE_TYPE,
  };
}

export function clearSelectedService() {
  return {
    type: CLEAR_SELECTED_SERVICE,
  };
}

export function clearSelectedLocation() {
  return {
    type: CLEAR_SELECTED_LOCATION,
  };
}

export function setBookingFlow(flowType) {
  return {
    type: SET_BOOKING_FLOW,
    flowType,
  };
}
export function updateAppointmentText(notificationObject) {
  return {
    type: UPDATE_APPOINTMENT_TEXT,
    notificationObject,
  };
}

export function updateAppointmentTextSuccess(result) {
  return {
    type: UPDATE_APPOINTMENT_TEXT_SUCCESS,
    result,
  };
}

export function updateAppointmentTextFailure(error) {
  return {
    type: UPDATE_APPOINTMENT_TEXT_ERROR,
    error,
  };
}
export function clearAppointmentBookingData() {
  return {
    type: CLEAR_APPOINTMENT_BOOKING_DATA,
  };
}

export function fetchUserCompletedAppointments(spabooker_id) {
  return {
    type: FETCH_USER_COMPLETED_APPOINTMENTS,
    spabooker_id,
  };
}

export function fetchUserCompletedAppointmentsSuccess(result) {
  return {
    type: FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS,
    result,
  };
}

export function fetchUserCompletedAppointmentsFailure(error) {
  return {
    type: FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE,
    error,
  };
}

// Used for My Appointments
export function fetchAppointments(status) {
  return {
    type: FETCH_APPOINTMENTS,
    status,
  };
}
export function fetchAppointmentsSuccess(result) {
  return {
    type: FETCH_APPOINTMENTS_SUCCESS,
    result,
  };
}

export function fetchAppointmentsFailure(error) {
  return {
    type: FETCH_APPOINTMENTS_FAILURE,
    error,
  };
}

// Used for Status fetch
export function fetchStatus(status) {
  return {
    type: FETCH_STATUS,
    status,
  };
}
export function fetchStatusSuccess(result) {
  return {
    type: FETCH_STATUS_SUCCESS,
    result,
  };
}

export function fetchStatusFailure(error) {
  return {
    type: FETCH_STATUS_FAILURE,
    error,
  };
}

export function cancelUserAppointment(appointment_id) {
  return {
    type: CANCEL_USER_APPOINTMENT,
    appointment_id,
  };
}

export function cancelUserAppointmentSuccess(result) {
  return {
    type: CANCEL_USER_APPOINTMENT_SUCCESS,
    result,
  };
}

export function cancelUserAppointmentFailure(error) {
  return {
    type: CANCEL_USER_APPOINTMENT_FAILURE,
    error,
  };
}

export function setFilterState(filterState) {
  return {
    type: SET_FILTER_STATE,
    filterState,
  };
}

export function clearFilterState() {
  return {
    type: CLEAR_FILTER_STATE,
  };
}

export function setCurrentTherapistClicked(currentTherapistClicked) {
  return {
    type: SET_CURRENT_THERAPIST_CLICKED,
    currentTherapistClicked,
  };
}

export function clearCurrentTherapistClicked() {
  return {
    type: CLEAR_CURRENT_THERAPIST_CLICKED,
  };
}

export function setCurrentSelectedDate(currentSelectedDate) {
  return {
    type: SET_CURRENT_SELECTED_DATE,
    currentSelectedDate,
  };
}

export function clearCurrentSelectedDate() {
  return {
    type: CLEAR_CURRENT_SELECTED_DATE,
  };
}

export function setEnteredPhoneNumber(enteredPhoneNumber) {
  return {
    type: SET_ENTERED_PHONE_NUMBER,
    enteredPhoneNumber,
  };
}

export function clearEnteredPhoneNumber() {
  return {
    type: CLEAR_ENTERED_PHONE_NUMBER,
  };
}

export function setAppointmentNetValue(appointmentNetValue) {
  return {
    type: SET_APPOINTMENT_NET_VALUE,
    appointmentNetValue,
  };
}

export function clearAppointmentNetValue() {
  return {
    type: CLEAR_APPOINTMENT_NET_VALUE,
  };
}

export function setEnteredPromoCode(enteredPromoCode) {
  return {
    type: SET_ENTERED_PROMO_CODE,
    enteredPromoCode,
  };
}

export function clearEnteredPromoCode() {
  return {
    type: CLEAR_ENTERED_PROMO_CODE,
  };
}

export function retryAppointmentCharge(spabooker_id) {
  return {
    type: RETRY_APPOINTMENT_CHARGE,
    spabooker_id,
  };
}

export function retryAppointmentChargeSuccess(result) {
  return {
    type: RETRY_APPOINTMENT_CHARGE_SUCCESS,
    result,
  };
}

export function retryAppointmentChargeFailure(error) {
  return {
    type: RETRY_APPOINTMENT_CHARGE_FAILURE,
    error,
  };
}

export function reserveAppointment(appointmentObj) {
  return {
    type: RESERVE_APPOINTMENT_SAGA,
    appointmentObj,
  };
}

export function applyReferral() {
  return {
    type: APPLY_REFERRAL,
  };
}

export function applyReferralSuccess(result) {
  return { type: APPLY_REFERRAL_SUCCESS, result };
}

export function fetchNearestLocation(payload) {
  return { type: FETCH_NEAREST_LOCATION, ...payload };
}

export function fetchNearestLocationSuccess(result) {
  return { type: FETCH_NEAREST_LOCATION_SUCCESS, result };
}

export function fetchNearestLocationFailure(error) {
  return { type: FETCH_NEAREST_LOCATION_FAILURE, error };
}

export function clearNearestLocations() {
  return {
    type: CLEAR_NEAREST_LOCATIONS,
  };
}

export function getLiabilityWaiver() {
  return { type: GET_LIABILITY_WAIVER };
}

export function getLiabilityWaiverSuccess(result) {
  return { type: GET_LIABILITY_WAIVER_SUCCESS, result };
}

export function getLiabilityWaiverFailure(error) {
  return { type: GET_LIABILITY_WAIVER_FAILURE, error };
}

export function sendSignature(imageString) {
  return { type: SEND_SIGNATURE, ...imageString };
}

export function sendSignatureSuccess(result) {
  return { type: SEND_SIGNATURE_SUCCESS, result };
}

export function sendSignatureFailure(error) {
  return { type: SEND_SIGNATURE_FAILURE, error };
}
