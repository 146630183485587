/*
 *
 * Membership reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_MEMBERSHIP_DETAILS,
  FETCH_MEMBERSHIP_DETAILS_SUCCESS,
  FETCH_MEMBERSHIP_DETAILS_FAILURE,
  SET_SELECTED_MEMBERSHIP_TYPE,
  PURCHASE_MEMBERSHIP,
  PURCHASE_MEMBERSHIP_SUCCESS,
  PURCHASE_MEMBERSHIP_FAILURE,
  CANCEL_MEMBERSHIP,
  CANCEL_MEMBERSHIP_SUCCESS,
  CANCEL_MEMBERSHIP_FAILURE,
  PAUSE_MEMBERSHIP,
  PAUSE_MEMBERSHIP_SUCCESS,
  PAUSE_MEMBERSHIP_FAILURE,
  RESUME_MEMBERSHIP,
  RESUME_MEMBERSHIP_SUCCESS,
  RESUME_MEMBERSHIP_FAILURE,
  CLEAR_MEMBERSHIP_PURCHASE_DATA,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE,
  CLEAR_MEMBERSHIP_DATA,
  CLEAR_MEMBERSHIP_ERROR,
  CLEAR_HOME_LOCATION,
  SET_HOME_LOCATION,
} from './constants';

export const initialState = fromJS({
  fetchingMembershipDetails: false,
  fetchMembershipDetailsError: null,
  membershipDetails: {},
  selectedMembershipType: {},
  purchasingMembership: false,
  purchasingMembershipError: null,
  purchaseMembershipResult: {},
  cancellingMembership: false,
  cancellingMembershipError: null,
  cancelMembershipResult: {},
  pausingMembership: false,
  pausingMembershipError: null,
  pauseMembershipResult: {},
  resumingMembership: false,
  resumingMembershipError: null,
  resumeMembershipResult: {},
  submittingMembershipCancellationReasons: false,
  submittingMembershipCancellationReasonsError: null,
  submitMembershipCancellationReasonsResult: {},
});

function membershipReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBERSHIP_DETAILS:
      return state.withMutations(s => {
        s.set('fetchingMembershipDetails', true)
          .set('fetchMembershipDetailsError', null)
          .set('membershipDetails', fromJS({}));
      });

    case FETCH_MEMBERSHIP_DETAILS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingMembershipDetails', false)
          .set('fetchMembershipDetailsError', null)
          .set('membershipDetails', fromJS(action.result));
      });

    case FETCH_MEMBERSHIP_DETAILS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingMembershipDetails', false)
          .set('fetchMembershipDetailsError', action.error)
          .set('membershipDetails', fromJS({}));
      });

    case SET_SELECTED_MEMBERSHIP_TYPE:
      return state.withMutations(s => {
        s.set('selectedMembershipType', fromJS(action.selectedMembershipType));
      });
    case PURCHASE_MEMBERSHIP:
      return state.withMutations(s => {
        s.set('purchasingMembership', true)
          .set('purchasingMembershipError', null)
          .set('purchaseMembershipResult', fromJS({}));
      });

    case PURCHASE_MEMBERSHIP_SUCCESS:
      return state.withMutations(s => {
        s.set('purchasingMembership', false)
          .set('purchasingMembershipError', null)
          .set('purchaseMembershipResult', fromJS(action.result));
      });

    case PURCHASE_MEMBERSHIP_FAILURE:
      return state.withMutations(s => {
        s.set('purchasingMembership', false)
          .set('purchasingMembershipError', action.error)
          .set('purchaseMembershipResult', fromJS({}));
      });

    case CLEAR_HOME_LOCATION:
      return state.withMutations(s => {
        s.set('homeLocationId', null);
      });

    case SET_HOME_LOCATION:
      return state.withMutations(s => {
        s.set('homeLocationId', fromJS(action.location_id));
      });

    case CANCEL_MEMBERSHIP:
      return state.withMutations(s => {
        s.set('cancellingMembership', true)
          .set('cancellingMembershipError', null)
          .set('cancelMembershipResult', fromJS({}));
      });

    case CANCEL_MEMBERSHIP_SUCCESS:
      return state.withMutations(s => {
        s.set('cancellingMembership', false)
          .set('cancellingMembershipError', null)
          .set('cancelMembershipResult', fromJS(action.result));
      });

    case CANCEL_MEMBERSHIP_FAILURE:
      return state.withMutations(s => {
        s.set('cancellingMembership', false)
          .set('cancellingMembershipError', action.error)
          .set('cancelMembershipResult', fromJS({}));
      });

    case PAUSE_MEMBERSHIP:
      return state.withMutations(s => {
        s.set('pausingMembership', true)
          .set('pausingMembershipError', null)
          .set('pauseMembershipResult', fromJS({}));
      });

    case PAUSE_MEMBERSHIP_SUCCESS:
      return state.withMutations(s => {
        s.set('pausingMembership', false)
          .set('pausingMembershipError', null)
          .set('pauseMembershipResult', fromJS(action.result));
      });

    case PAUSE_MEMBERSHIP_FAILURE:
      return state.withMutations(s => {
        s.set('pausingMembership', false)
          .set('pausingMembershipError', action.error)
          .set('pauseMembershipResult', fromJS({}));
      });

    case RESUME_MEMBERSHIP:
      return state.withMutations(s => {
        s.set('resumingMembership', true)
          .set('resumingMembershipError', null)
          .set('resumeMembershipResult', fromJS({}));
      });

    case RESUME_MEMBERSHIP_SUCCESS:
      return state.withMutations(s => {
        s.set('resumingMembership', false)
          .set('resumingMembershipError', null)
          .set('resumeMembershipResult', fromJS(action.result));
      });

    case RESUME_MEMBERSHIP_FAILURE:
      return state.withMutations(s => {
        s.set('resumingMembership', false)
          .set('resumingMembershipError', action.error)
          .set('resumeMembershipResult', fromJS({}));
      });

    case CLEAR_MEMBERSHIP_PURCHASE_DATA:
      return state.withMutations(s => {
        s.set('resumingMembership', false)
          .set('resumingMembershipError', null)
          .set('resumeMembershipResult', fromJS({}));
      });

    case SUBMIT_MEMBERSHIP_CANCELLATION_REASONS:
      return state.withMutations(s => {
        s.set('submittingMembershipCancellationReasons', true)
          .set('submittingMembershipCancellationReasonsError', null)
          .set('submitMembershipCancellationReasonsResult', fromJS({}));
      });

    case SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS:
      return state.withMutations(s => {
        s.set('submittingMembershipCancellationReasons', false)
          .set('submittingMembershipCancellationReasonsError', null)
          .set(
            'submitMembershipCancellationReasonsResult',
            fromJS(action.result),
          );
      });

    case SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE:
      return state.withMutations(s => {
        s.set('submittingMembershipCancellationReasons', false)
          .set('submittingMembershipCancellationReasonsError', action.error)
          .set('submitMembershipCancellationReasonsResult', fromJS({}));
      });

    case CLEAR_MEMBERSHIP_DATA:
      return initialState;

    case 'RESET_ACTION':
      return initialState;

    case CLEAR_MEMBERSHIP_ERROR:
      return state.withMutations(s => {
        s.set('purchasingMembershipError', null);
      });

    default:
      return state;
  }
}

export default membershipReducer;
