import PropType from 'prop-types';
import React from 'react';
import pat from '../../images/about/LoadAnimation.gif';
import SvgIcon from '../SvgIcon';

const style = {
  background: 'rgba(255,255,255, 0.8)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 100,
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
};
function LoadingScreen(props) {
  const { hangTight, loaderMessage } = props;
  if (!hangTight) {
    return (
      <div className="review-loader">
        <h3>{loaderMessage}</h3>
        <div className="rotate-animate">
          <SvgIcon icon="happy" size="normal" />
        </div>
      </div>
    );
  }
  return (
    <div className="loader-overlay">
      <div style={style}>
        <div className="bubble">
          <p>Hang tight!</p>
        </div>
        <img src={pat} alt="loading" />
      </div>
    </div>
  );
}

LoadingScreen.propTypes = {
  loaderMessage: PropType.string,
  hangTight: PropType.string,
};

export default LoadingScreen;
