/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

/* eslint-disable react/prefer-stateless-function */

import ReverseCurve from '../../components/ReverseCurve';
import { analytics } from '../../utils/common';
import KlaviyoList from '../KlaviyoList';
import { makeSelectUser } from '../User/selectors';
const phoneNumber = '1-855-858-6263';
class Footer extends Component {
  static defaultProps = {
    showItems: true,
  };
  isMember() {
    const {
      user: { userData },
    } = this.props;
    return Number(userData.has_membership) === 1;
  }

  render() {
    const { showItems, hideMobile } = this.props;
    const hidePhone = hideMobile
      ? 'footerAllPages hide-phone'
      : 'footerAllPages';

    return (
      <div className={hidePhone}>
        <ReverseCurve />

        <div id="footer" className="footer">
          <div className="footer__container container">
            {!showItems && (
              <div className="footer__section  footer__section--empty" />
            )}
            <div className="footer__section phone-only">
              <div className="footer__column hide-phone">
                <div className="footer__logo">
                  <i className="icon icon--logo-footer logo" />
                </div>
              </div>
            </div>
            {showItems && (
              <div className="footer__section">
                <div className="footer__column">
                  <ul className="footer__links">
                    <li className="footer__link">
                      <Link
                        to="/how-it-works"
                        onClick={() => {
                          analytics('Footer - How it Works', null, true);
                        }}
                      >
                        How It Works
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/therapist/list"
                        onClick={() => {
                          analytics('Footer - Meet our Therapists', null, true);
                        }}
                      >
                        Meet Our Therapists
                      </Link>
                    </li>

                    <li className="footer__link">
                      <Link
                        to={this.isMember() ? '/membership' : '/pricing'}
                        onClick={() => {
                          analytics('Footer - Pricing', null, true);
                        }}
                      >
                        Pricing
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/membership"
                        onClick={() => {
                          analytics('Footer - Memberships', null, true);
                        }}
                      >
                        Memberships
                      </Link>
                    </li>
                    <li className="footer__link">
                      <a
                        href="http://gallery.squeezemassage.com/press"
                        target="_blank"
                        onClick={() => {
                          analytics('Footer - Press', null, true);
                        }}
                      >
                        Press
                      </a>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/gift"
                        onClick={() => {
                          analytics('Footer - Give a Gift', null, true);
                        }}
                      >
                        Give a Gift
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="footer__column">
                  <ul className="footer__links">
                    <li className="footer__link">
                      <Link
                        to="/about-us"
                        onClick={() => {
                          analytics('Footer - About Us', null, true);
                        }}
                      >
                        About Us
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/careers"
                        onClick={() => {
                          analytics('Footer - Careers', null, true);
                        }}
                      >
                        Careers
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/appointment/locations?purchase_funnel=false"
                        onClick={() => {
                          analytics('Footer - Locations', null, true);
                        }}
                      >
                        Locations
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/franchising"
                        onClick={() => {
                          analytics('Footer - Own', null, true);
                        }}
                      >
                        FRANCHISE
                      </Link>
                    </li>

                    <li className="footer__link">
                      <a
                        href="http://gallery.squeezemassage.com"
                        target="_blank"
                        onClick={() => {
                          analytics('Footer - Gallery', null, true);
                        }}
                      >
                        Gallery
                      </a>
                    </li>

                    <li className="footer__link">
                      <a href="/philanthropy">Philanthropy</a>
                    </li>
                    {/* <li className="footer__link">
                      <a
                        href="https://blog.squeezemassage.com"
                        target="_blank"
                        onClick={() => {
                          analytics('Footer - Blog', null, true);
                        }}
                      >
                        Blog
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            )}
            <div className="footer__section footer__section--middle hide-phone">
              {showItems && (
                <div className="footer__column">
                  <div className="footer__social-links">
                    <a
                      href="https://www.facebook.com/squeezemassage/"
                      target="_blank"
                      aria-label="facebook"
                      onClick={() => {
                        analytics('Footer - Facebook', null, true);
                      }}
                    >
                      <i className="icon icon--facebook" />
                    </a>
                    <a
                      href="https://twitter.com/squeezemassage"
                      target="_blank"
                      aria-label="twitter"
                      onClick={() => {
                        analytics('Footer - Twitter', null, true);
                      }}
                    >
                      <i className="icon icon--twitter" />
                    </a>
                  </div>
                </div>
              )}
              <div className="footer__column">
                {showItems && (
                  <div className="footer__bubble">
                    <i className="icon icon--speech-bubble" />
                    <div className="footer__bubble-text">
                      A Way Better Massage Experience!
                    </div>
                  </div>
                )}
                <div className="footer__logo">
                  <i className="icon icon--smiley-white-40x40" />
                  <i className="icon icon--logo-footer logo" />
                </div>
              </div>
              {showItems && (
                <div className="footer__column">
                  <div className="footer__social-links">
                    <a
                      href="https://www.pinterest.com/squeezemassage/"
                      target="_blank"
                      aria-label="pinterest"
                      onClick={() => {
                        analytics('Footer - Pinterest', null, true);
                      }}
                    >
                      <i className="icon icon--pinterest" />
                    </a>
                    <a
                      href="https://www.instagram.com/squeeze/"
                      target="_blank"
                      aria-label="instagram"
                      onClick={() => {
                        analytics('Footer - Instagram', null, true);
                      }}
                    >
                      <i className="icon icon--instagram" />
                    </a>
                  </div>
                </div>
              )}
            </div>
            {showItems && (
              <div className="footer__section">
                <div className="footer__column">
                  <ul className="footer__links">
                    {/* <li className="footer__link">
                      <a
                        href="https://help.squeezemassage.com"
                        target="_blank"
                        onClick={() => {
                          analytics('Footer - FAQ', null, true);
                        }}
                      >
                        FAQ
                      </a>
                    </li> */}
                    <li className="footer__link">
                      <a href="https://help.squeezemassage.com" target="_blank">
                        Help
                      </a>
                    </li>
                    <li className="footer__link footerNumber">{phoneNumber}</li>
                    <li className="footer__link">
                      <Link
                        to="/terms-of-use"
                        onClick={() => {
                          analytics('Footer - Terms of Use', null, true);
                        }}
                      >
                        Terms of Use
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/privacy-policy"
                        onClick={() => {
                          analytics('Footer - Privacy Policy', null, true);
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="footer__link">
                      <Link
                        to="/terms-of-use?membership_terms"
                        onClick={() => {
                          analytics('Footer - Membership Terms', null, true);
                        }}
                      >
                        Membership Terms
                      </Link>
                    </li>
                    <li className="footer__link">
                      <a href="https://thefeelgoodcompany.com/" target="_blank">
                        The Feel Good Company
                      </a>
                    </li>
                    {/* <li className="footer__link">
                      <Link to="/advisors">OUR ADVISORS</Link>
                    </li> */}
                  </ul>
                </div>
                <div className="footer__column footer__column--last">
                  <div className="footer__form-header">
                    <div className="footer__form-title hide-phone">
                      Let's Stay In Touch
                    </div>
                    <div className="footer__form-desc">
                      Get informed on new locations and special offers.
                    </div>
                  </div>
                  <KlaviyoList type="footer" {...this.props} />
                  <div className="footer__social-links phone-only">
                    <a
                      href="https://www.facebook.com/squeezemassage/"
                      target="_blank"
                      onClick={() => {
                        analytics('Footer - Facebook', null, true);
                      }}
                    >
                      <i className="icon icon--facebook" />
                    </a>
                    <a
                      href="https://twitter.com/squeezemassage"
                      target="_blank"
                      onClick={() => {
                        analytics('Footer - Twitter', null, true);
                      }}
                    >
                      <i className="icon icon--twitter" />
                    </a>
                    <a
                      href="https://www.pinterest.com/squeezemassage/"
                      target="_blank"
                      onClick={() => {
                        analytics('Footer - Pinterest', null, true);
                      }}
                    >
                      <i className="icon icon--pinterest" />
                    </a>
                    <a
                      href="https://www.instagram.com/squeeze/"
                      target="_blank"
                      onClick={() => {
                        analytics('Footer - Instagram', null, true);
                      }}
                    >
                      <i className="icon icon--instagram" />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {!showItems && (
              <div className="footer__section  footer__section--empty" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  showItems: PropTypes.bool,
  hideMobile: PropTypes.bool,
  user: PropTypes.object,
};
const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  withRouter,
)(Footer);
