import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';

import '../../css/promomessage.css';

function check(props) {
  if (props.passedProps.urlArr.indexOf(props.location.pathname) >= 0) {
    return true;
  }
  return false;
}

function PromoMessage(props) {
  const shouldShow = check(props);
  return (
    <div className={shouldShow ? 'promoCont' : 'hide'}>
      <span className="tagLine">{props.passedProps.tagLine}&nbsp; </span>
      <a
        className="linkText"
        href={props.passedProps.redirectTo}
        target="_blank"
      >
        {props.passedProps.linkText}
      </a>
      {/* <button
        className="linkText"
        onClick={
          (
          () => {
            props.history.push('/gift/single');
            analytics('Top Hat Promo', null, true);
          })
        }
      >
        {props.passedProps.linkText}
      </button> */}
    </div>
  );
}

PromoMessage.propTypes = {
  passedProps: PropTypes.object,
  // history: PropTypes.object,
};

export default withRouter(PromoMessage);
