import { fromJS } from 'immutable';
import {
  CLEAR_GIFT_DATA,
  CLEAR_GIFT_PURCHASE_DATA,
  PURCHASE_GIFT,
  PURCHASE_GIFT_FAILURE,
  PURCHASE_GIFT_SUCCESS,
  SET_AMOUNT,
  SET_GIFT_TOTAL,
  SET_MEMBERSHIP_DURATION,
  SET_SELECTED_GIFT_SUB_TYPE,
  SET_SELECTED_GIFT_SUB_TYPE_QUANTITY,
  SET_SELECTED_GIFT_TYPE,
  SET_SERVICES,
  SET_SHIPPING_DETAILS,
} from './constants';
const initialState = fromJS({
  selectedGiftType: '',
  selectedGiftSubType: '',
  selectedGiftSubTypeQuantity: '',
  services: [],
  membershipDuration: '',
  shippingDetails: {},
  purchasingGift: false,
  purchaseGiftError: null,
  purchaseGiftResult: {},
});

function giftingReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_GIFT_TYPE:
      return state.withMutations(s => {
        s.set('selectedGiftType', fromJS(action.giftType));
      });
    case SET_SELECTED_GIFT_SUB_TYPE:
      return state.withMutations(s => {
        s.set('selectedGiftSubType', fromJS(action.subType));
      });
    case SET_SELECTED_GIFT_SUB_TYPE_QUANTITY:
      return state.withMutations(s => {
        s.set('selectedGiftSubTypeQuantity', fromJS(action.quantity));
      });
    case SET_SERVICES:
      return state.withMutations(s => {
        s.set('services', action.services);
      });
    case SET_MEMBERSHIP_DURATION:
      return state.withMutations(s => {
        s.set('membershipDuration', action.duration);
      });
    case SET_AMOUNT:
      return state.withMutations(s => {
        s.set('amount', action.amount);
      });

    case SET_SHIPPING_DETAILS:
      return state.withMutations(s => {
        s.set('shippingDetails', action.shippingDetails);
      });

    case PURCHASE_GIFT:
      return state.withMutations(s => {
        s.set('purchasingGift', true)
          .set('purchaseGiftError', null)
          .set('purchaseGiftResult', fromJS({ 1: 1 }));
      });

    case PURCHASE_GIFT_SUCCESS:
      return state.withMutations(s => {
        s.set('purchasingGift', false)
          .set('purchaseGiftError', null)
          .set('purchaseGiftResult', fromJS(action.result));
      });

    case PURCHASE_GIFT_FAILURE:
      return state.withMutations(s => {
        s.set('purchasingGift', false)
          .set('purchaseGiftError', action.error)
          .set('purchaseGiftResult', fromJS({ 2: 2 }));
      });

    case CLEAR_GIFT_PURCHASE_DATA:
      return state.withMutations(s => {
        s.set('purchasingGift', false)
          .set('purchaseGiftError', null)
          .set('purchaseGiftResult', fromJS({ 3: 3 }));
      });
    case SET_GIFT_TOTAL:
      return state.withMutations(s => {
        s.set('netTotal', action.total);
      });

    case CLEAR_GIFT_DATA:
      return initialState;

    case 'RESET_ACTION':
      return initialState;

    default:
      return state;
  }
}

export default giftingReducer;
