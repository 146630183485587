/* eslint-disable camelcase */
import Config from 'app.config';
import { pickBy } from 'lodash-es';
import { call, put, takeLatest } from 'redux-saga/effects';
import { yieldErrorHandling } from 'utils/common';
import request, { buildRequestObj } from '../../utils/request';
import {
  cancelMembershipFailure,
  cancelMembershipSuccess,
  fetchMembershipDetailsFailure,
  fetchMembershipDetailsSuccess,
  pauseMembershipFailure,
  pauseMembershipSuccess,
  purchaseMembershipFailure,
  purchaseMembershipSuccess,
  resumeMembershipFailure,
  resumeMembershipSuccess,
  submitMembershipCancellationReasonsFailure,
  submitMembershipCancellationReasonsSuccess,
} from './actions';
import {
  CANCEL_MEMBERSHIP,
  FETCH_MEMBERSHIP_DETAILS,
  PAUSE_MEMBERSHIP,
  PURCHASE_MEMBERSHIP,
  RESUME_MEMBERSHIP,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS,
} from './constants';

export function* fetchMembershipDetailsSaga(action) {
  const reqObj = buildRequestObj('GET', {}, true);
  const reqUrl = `${Config.apiBasev2}/membership`;

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(fetchMembershipDetailsSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      fetchMembershipDetailsFailure,
      'user membership detail fetch failed',
    );
  }
}

function* watchFetchMembershipDetails() {
  yield takeLatest(FETCH_MEMBERSHIP_DETAILS, fetchMembershipDetailsSaga);
}

export function* purchaseMembershipSaga(actionData) {
  const { action, plan, coupon, location_id } = actionData;
  const reqUrl = `${Config.apiBasev2}/membership`;
  const membershipObj = pickBy({
    plan_id: plan,
    location_id,
    code: coupon,
  });
  if (action === 'downgrade' || action === 'upgrade') {
    membershipObj.action = action;
    delete membershipObj.location_id;
  }
  const method =
    action === 'downgrade' || action === 'upgrade' ? 'PUT' : 'POST';
  const reqObj = buildRequestObj(method, membershipObj, true);

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(purchaseMembershipSuccess(result));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      purchaseMembershipFailure,
      'membership purchase failed',
    );
  }
}

function* watchPurchaseMembership() {
  yield takeLatest(PURCHASE_MEMBERSHIP, purchaseMembershipSaga);
}

export function* cancelMembershipSaga(action) {
  const reqUrl = `${Config.apiBasev2}/membership`;
  const reqObj = buildRequestObj('PUT', { action: 'cancel' }, true);

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(cancelMembershipSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      cancelMembershipFailure,
      'membership cancellation failed',
    );
  }
}

function* watchCancelMembership() {
  yield takeLatest(CANCEL_MEMBERSHIP, cancelMembershipSaga);
}

export function* pauseMembershipSaga(action) {
  const { isUpdatePausedDate, resume_pause_date, membership_id } = action;
  const reqUrl = isUpdatePausedDate
    ? `${Config.apiBasev2}/membership/${membership_id}`
    : `${Config.apiBasev2}/membership`;
  const reqObj = buildRequestObj(
    'PUT',
    isUpdatePausedDate
      ? { resume_at: resume_pause_date }
      : { action: 'pause', resume_at: resume_pause_date },
    true,
  );

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(pauseMembershipSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      pauseMembershipFailure,
      'membership pause failed',
    );
  }
}

function* watchPauseMembership() {
  yield takeLatest(PAUSE_MEMBERSHIP, pauseMembershipSaga);
}

export function* resumeMembershipSaga() {
  const reqUrl = `${Config.apiBasev2}/membership`;
  const reqObj = buildRequestObj('PUT', { action: 'resume' }, true);

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(resumeMembershipSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      resumeMembershipFailure,
      'membership resume failed',
    );
  }
}

function* watchResumeMembership() {
  yield takeLatest(RESUME_MEMBERSHIP, resumeMembershipSaga);
}

export function* submitMembershipCancellationReasonsSaga(action) {
  const { reason } = action;
  const reqUrl = `${Config.apiBasev2}/membership`;
  const reqObj = buildRequestObj('PUT', { cancel_reason: reason }, true);

  try {
    const result = yield call(request, reqUrl, reqObj);
    yield put(submitMembershipCancellationReasonsSuccess({ success: true }));
  } catch (error) {
    yield yieldErrorHandling(
      error,
      submitMembershipCancellationReasonsFailure,
      'membership cancellation reason submit failed',
    );
  }
}

function* watchSubmitMembershipCancellationReasons() {
  yield takeLatest(
    SUBMIT_MEMBERSHIP_CANCELLATION_REASONS,
    submitMembershipCancellationReasonsSaga,
  );
}

export default {
  watchFetchMembershipDetails,
  watchPurchaseMembership,
  watchCancelMembership,
  watchPauseMembership,
  watchResumeMembership,
  watchSubmitMembershipCancellationReasons,
};
