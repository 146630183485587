/**
 *
 * SectionContainer
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

function SectionContainer(props) {
  return (
    <section className="meet-squeeze" style={props.style}>
      <div className="container">{props.children}</div>
    </section>
  );
}

SectionContainer.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
};

export default SectionContainer;
