/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { fromJS } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { LOCATION_CHANGE } from 'react-router-redux';
import { reducer as reduxFormReducer } from 'redux-form/immutable';
import appointmentReducer from './containers/Appointment/reducer';
import therapistReducer from './containers/Therapist/reducer';
import userReducer from './containers/User/reducer';
import membershipReducer from './containers/Membership/reducer';
import giftReducer from './containers/Gift/reducer';
import printReducer from './containers/Print/reducer';
import preferencesReducer from './containers/Preferences/reducer';
import inviteReducer from './containers/InviteFriends/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
});

/**
 * Merge route into the global application state
 */
export function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      });
    default:
      return state;
  }
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    route: routeReducer,
    form: reduxFormReducer,
    appointment: appointmentReducer,
    therapist: therapistReducer,
    user: userReducer,
    membership: membershipReducer,
    gift: giftReducer,
    preferences: preferencesReducer,
    invite: inviteReducer,
    print: printReducer,
    ...injectedReducers,
  });
}
