/*
 *
 * Therapist reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_THERAPISTS,
  FETCH_THERAPISTS_SUCCESS,
  FETCH_THERAPISTS_FAILURE,
  FETCH_THERAPIST_REVIEWS,
  FETCH_THERAPIST_REVIEWS_SUCCESS,
  FETCH_THERAPIST_REVIEWS_FAILURE,
  FETCH_THERAPIST_DETAIL,
  FETCH_THERAPIST_DETAIL_SUCCESS,
  FETCH_THERAPIST_DETAIL_FAILURE,
  SET_SELECTED_THERAPIST,
  TIP_THERAPIST,
  TIP_THERAPIST_SUCCESS,
  TIP_THERAPIST_FAILURE,
  CLEAR_TIP_THERAPIST_DATA,
  SET_TIPPED_APPOINTMENT,
  CLEAR_TIPPED_APPOINTMENT,
  RATE_THERAPIST,
  RATE_THERAPIST_SUCCESS,
  RATE_THERAPIST_FAILURE,
  FETCH_USER_THERAPISTS,
  FETCH_USER_THERAPISTS_SUCCESS,
  FETCH_USER_THERAPISTS_FAILURE,
  FAVORITE_THERAPIST,
  FAVORITE_THERAPIST_SUCCESS,
  FAVORITE_THERAPIST_FAILURE,
  UNFAVORITE_THERAPIST,
  UNFAVORITE_THERAPIST_SUCCESS,
  UNFAVORITE_THERAPIST_FAILURE,
  UPDATE_PRIVATE_NOTE,
  UPDATE_PRIVATE_NOTE_FAILURE,
  UPDATE_PRIVATE_NOTE_SUCCESS,
} from './constants';

export const initialState = fromJS({
  therapists: [],
  fetchingTherapists: false,
  fetchingTherapistsError: null,
  therapistReviews: [],
  therapistReviewsCount: 0,
  fetchingTherapistReviews: false,
  fetchingTherapistReviewsError: null,
  therapistDetail: {},
  fetchingTherapistDetail: false,
  fetchingTherapistDetailError: null,
  tippingTherapist: false,
  tippingTherapistError: null,
  tippingTherapistResult: {},
  tippedAppointment: {},
  ratingTherapist: false,
  ratingTherapistError: null,
  ratingTherapistResult: {},
  fetchingUserTherapists: false,
  fetchingUserTherapistsError: null,
  userTherapists: [],
  favoritingTherapist: false,
  favoritingTherapistError: null,
  favoritingTherapistResult: {},
  unFavoritingTherapist: false,
  unFavoritingTherapistError: null,
  unFavoritingTherapistResult: {},
  updatingPrivateNote: false,
  updatingPrivateNoteError: null,
  updatingPrivateNoteResult: {},
});

function therapistReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_THERAPISTS:
      return state.withMutations(s => {
        s.set('fetchingTherapists', true)
          .set('fetchingTherapistsError', null)
          .set('therapists', fromJS({}));
      });

    case FETCH_THERAPISTS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingTherapists', false)
          .set('fetchingTherapistsError', null)
          .set('therapists', fromJS(action.result));
      });

    case FETCH_THERAPISTS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingTherapists', false)
          .set('fetchingTherapistsError', action.error)
          .set('therapists', fromJS({}));
      });

    case FETCH_THERAPIST_REVIEWS:
      return state.withMutations(s => {
        s.set('fetchingTherapistReviews', true)
          .set('fetchingTherapistReviewsError', null)
          .set('therapistReviews', fromJS({}));
      });

    case FETCH_THERAPIST_REVIEWS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingTherapistReviews', false)
          .set('fetchingTherapistReviewsError', null)
          .set('therapistReviews', fromJS(action.result.items))
          .set('therapistReviewsCount', fromJS(action.result.count));
      });

    case FETCH_THERAPIST_REVIEWS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingTherapistReviews', false)
          .set('fetchingTherapistReviewsError', action.error)
          .set('therapistReviews', fromJS({}));
      });

    case FETCH_THERAPIST_DETAIL:
      return state.withMutations(s => {
        s.set('fetchingTherapistDetail', true)
          .set('fetchingTherapistDetailError', null)
          .set('therapistDetail', fromJS({}));
      });

    case FETCH_THERAPIST_DETAIL_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingTherapistDetail', false)
          .set('fetchingTherapistDetailError', null)
          .set('therapistDetail', fromJS(action.result));
      });

    case FETCH_THERAPIST_DETAIL_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingTherapistDetail', false)
          .set('fetchingTherapistDetailError', action.error)
          .set('therapistDetail', fromJS({}));
      });

    case SET_SELECTED_THERAPIST:
      return state.withMutations(s => {
        s.set('therapistDetail', fromJS(action.therapist));
      });

    case TIP_THERAPIST:
      return state.withMutations(s => {
        s.set('tippingTherapist', true)
          .set('tippingTherapistError', null)
          .set('tippingTherapistResult', fromJS({}));
      });

    case TIP_THERAPIST_SUCCESS:
      return state.withMutations(s => {
        s.set('tippingTherapist', false)
          .set('tippingTherapistError', null)
          .set('tippingTherapistResult', fromJS(action.result));
      });

    case TIP_THERAPIST_FAILURE:
      return state.withMutations(s => {
        s.set('tippingTherapist', false)
          .set('tippingTherapistError', action.error)
          .set('tippingTherapistResult', fromJS({}));
      });

    case CLEAR_TIP_THERAPIST_DATA:
      return state.withMutations(s => {
        s.set('tippingTherapist', false)
          .set('tippingTherapistError', null)
          .set('tippingTherapistResult', fromJS({}));
      });

    case SET_TIPPED_APPOINTMENT:
      return state.withMutations(s => {
        s.set('tippedAppointment', fromJS(action.appointment));
      });

    case CLEAR_TIPPED_APPOINTMENT:
      return state.withMutations(s => {
        s.set('tippedAppointment', fromJS({}));
      });

    case RATE_THERAPIST:
      return state.withMutations(s => {
        s.set('ratingTherapist', true)
          .set('ratingTherapistError', null)
          .set('ratingTherapistResult', fromJS({}));
      });

    case RATE_THERAPIST_SUCCESS:
      return state.withMutations(s => {
        s.set('ratingTherapist', false)
          .set('ratingTherapistError', null)
          .set('ratingTherapistResult', fromJS(action.result));
      });

    case RATE_THERAPIST_FAILURE:
      return state.withMutations(s => {
        s.set('ratingTherapist', false)
          .set('ratingTherapistError', action.error)
          .set('ratingTherapistResult', fromJS({}));
      });
    case FETCH_USER_THERAPISTS:
      return state.withMutations(s => {
        s.set('fetchingUserTherapists', true)
          .set('fetchingTherapistsError', null)
          .set('userTherapists', fromJS({}));
      });

    case FETCH_USER_THERAPISTS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingUserTherapists', false)
          .set('fetchingTherapistsError', null)
          .set('userTherapists', fromJS(action.result));
      });

    case FETCH_USER_THERAPISTS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingUserTherapists', false)
          .set('fetchingTherapistsError', action.error)
          .set('userTherapists', fromJS({}));
      });

    case FAVORITE_THERAPIST:
      return state.withMutations(s => {
        s.set('favoritingTherapist', true)
          .set('favoritingTherapistError', null)
          .set('favoritingTherapistResult', fromJS({}));
      });

    case FAVORITE_THERAPIST_SUCCESS:
      return state.withMutations(s => {
        s.set('favoritingTherapist', false)
          .set('favoritingTherapistError', null)
          .set('favoritingTherapistResult', fromJS(action.result));
      });

    case FAVORITE_THERAPIST_FAILURE:
      return state.withMutations(s => {
        s.set('favoritingTherapist', false)
          .set('favoritingTherapistError', action.error)
          .set('favoritingTherapistResult', fromJS({}));
      });

    case UNFAVORITE_THERAPIST:
      return state.withMutations(s => {
        s.set('unFavoritingTherapist', true)
          .set('unFavoritingTherapistError', null)
          .set('unFavoritingTherapistResult', fromJS({}));
      });

    case UNFAVORITE_THERAPIST_SUCCESS:
      return state.withMutations(s => {
        s.set('unFavoritingTherapist', false)
          .set('unFavoritingTherapistError', null)
          .set('unFavoritingTherapistResult', fromJS(action.result));
      });

    case UNFAVORITE_THERAPIST_FAILURE:
      return state.withMutations(s => {
        s.set('unFavoritingTherapist', false)
          .set('unFavoritingTherapistError', action.error)
          .set('unFavoritingTherapistResult', fromJS({}));
      });

    case UPDATE_PRIVATE_NOTE:
      return state.withMutations(s => {
        s.set('updatingPrivateNote', true)
          .set('updatingPrivateNoteError', null)
          .set('updatingPrivateNoteResult', fromJS({}));
      });

    case UPDATE_PRIVATE_NOTE_SUCCESS:
      return state.withMutations(s => {
        s.set('updatingPrivateNote', false)
          .set('updatingPrivateNoteError', null)
          .set('updatingPrivateNoteResult', fromJS(action.result));
      });

    case UPDATE_PRIVATE_NOTE_FAILURE:
      return state.withMutations(s => {
        s.set('updatingPrivateNote', false)
          .set('unFavoritingTherapistError', action.error)
          .set('updatingPrivateNoteResult', fromJS({}));
      });

    case 'RESET_ACTION':
      return initialState;

    default:
      return state;
  }
}

export default therapistReducer;
