/*
 *
 * Preferences reducer
 *
 */

import { fromJS } from 'immutable';
import {
  FETCH_PREFERENCES_SUCCESS,
  FETCH_PREFERENCES,
  UPDATE_PREFERENCES,
  UPDATE_PREFERENCES_SUCCESS,
  FETCHING_PREFERENCES,
  UPDATING_PREFERENCES,
  UPDATED_PREFERENCES,
  PREFERENCES,
  RESET_UPDATED_PREFERENCES,
  FETCH_PREFERENCES_FAILURE,
} from './constants';

export const initialState = fromJS({
  fetchingPreferences: false,
  preferences: [],
  updatingPreferences: false,
  updatedPreferences: [],
});

function preferencesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_PREFERENCES:
      return state.withMutations(s => {
        s.set(FETCHING_PREFERENCES, true)
          .set(UPDATING_PREFERENCES, false)
          .set(UPDATED_PREFERENCES, false)
          .set(PREFERENCES, fromJS([]));
      });
    case FETCH_PREFERENCES_SUCCESS:
      return state.withMutations(s => {
        s.set(PREFERENCES, action.result).set(FETCHING_PREFERENCES, false);
      });
    case FETCH_PREFERENCES_FAILURE:
      return state.withMutations(s => {
        s.set(PREFERENCES, action.result).set(FETCHING_PREFERENCES, false);
      });
    case UPDATE_PREFERENCES:
      return state.withMutations(s => {
        s.set(UPDATING_PREFERENCES, true)
          .set(PREFERENCES, fromJS([]))
          .set(UPDATED_PREFERENCES, false);
      });
    case RESET_UPDATED_PREFERENCES:
      return state.withMutations(s => {
        s.set(UPDATED_PREFERENCES, false);
      });
    case UPDATE_PREFERENCES_SUCCESS:
      return state.withMutations(s => {
        s.set(UPDATING_PREFERENCES, false)
          .set(PREFERENCES, action.result)
          .set(UPDATED_PREFERENCES, true);
      });

    case 'RESET_ACTION':
      return initialState;

    default:
      return state;
  }
}

export default preferencesReducer;
