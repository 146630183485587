import React from 'react';
// import styled from 'styled-components';
import { Redirect, withRouter } from 'react-router-dom';

import { makeSelectUser } from 'containers/User/selectors';
import { connect } from 'react-redux';
import { StripeProvider } from 'react-stripe-elements';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import Config from '../../app.config';
import EligibleRoute from './EligibleRoute';

class PrivateRoute extends React.PureComponent {
  render() {
    const {
      component: Component,
      user,
      location,
      toPath,
      injectStripe,
      eligibleOnly,
      ...rest
    } = this.props; // eslint-disable-line react/prop-types
    if (!user.userData.id) {
      return (
        <Redirect
          to={{
            pathname: toPath || '/register',
            search: location.search,
            state: { from: this.props.location }, // eslint-disable-line react/prop-types
          }}
        />
      );
    }
    if (injectStripe) {
      return (
        <StripeProvider apiKey={Config.stripeApiKey}>
          <Component {...rest} />
        </StripeProvider>
      );
    }
    return eligibleOnly ? (
      <EligibleRoute {...rest} component={Component} />
    ) : (
      <Component {...rest} />
    );
  }
}

const mapStateToProps = createStructuredSelector({
  user: makeSelectUser(),
});
const withConnect = connect(mapStateToProps);

export default compose(
  withRouter,
  withConnect,
)(PrivateRoute);
