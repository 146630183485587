import Config from 'app.config';
import dayjs from 'dayjs';
import { trim } from 'lodash-es';
import validate from 'validate.js';

export const PHONE_REGEX = /\(\d{3}\) \d{3}-\d{4}/;

export const validatePresence = (value, allValues, props, name) => {
  const { nameLabelMap } = props;
  let labelText;
  if (props && props.children) {
    labelText = getLabelText(props.children, name);
  }
  return validate.single(value, {
    presence: {
      message: presenceErrorMsg(
        labelText,
        nameLabelMap && nameLabelMap[name] ? nameLabelMap[name] : name,
      ),
      allowEmpty: false,
    },
  });
};

function presenceErrorMsg(labelText, name) {
  return `Enter ${labelText || name}`;
}

function getLabelText(children, name) {
  const isArray = Array.isArray(children);
  if (isArray) {
    let i;
    for (i = 0; i < children.length; i += 1) {
      const labelText = checkProps(children[i].props, name);
      if (labelText) {
        return labelText;
      }
    }
    return false;
  }
  return checkProps(children.props, name);
}

function checkProps(props, name) {
  if (props && props.name === name) {
    return props.labelText;
  } else if (props && props.children) {
    return getLabelText(props.children, name);
  }
  return false;
}

// is not a valid email!
export const validateEmail = (value, allValues, props, name) => {
  const trimmedValue = value && value.trim();
  let labelText;
  const { nameLabelMap } = props;
  if (props && props.children) {
    labelText = getLabelText(props.children, name);
  }
  return validate.single(trimmedValue, {
    presence: {
      message: presenceErrorMsg(
        labelText,
        nameLabelMap && nameLabelMap[name] ? nameLabelMap[name] : name,
      ),
      allowEmpty: false,
    },
    email: true,
  });
};

// is too short (minimum is 6 characters)!
export const validatePassword = (value, allValues, props, name) => {
  const trimmedValue = value && value.trim();
  let labelText;
  if (props && props.children) {
    labelText = getLabelText(props.children, name);
  }
  return validate.single(trimmedValue, {
    presence: { message: presenceErrorMsg(labelText, name), allowEmpty: false },
  });
};

export const validateDateOfBirth = (value, allValues, props, name) => {
  const err = validatePresence(value, allValues, props, name);
  if (err) {
    return err;
  }
  const valStr = value.toString();
  const dob = dayjs(valStr);
  if (!dob.isValid() || trim(valStr).length < 10) {
    return [validationMessages.dateOfBirth];
  }
  const hundredyrago = dayjs().subtract(100, 'years');
  if (dob.isBefore(hundredyrago, 'year')) {
    return [validationMessages.dateOfBirthTooOld];
  }
  const now = dayjs();
  if (dob.isAfter(now)) {
    return [validationMessages.dateOfBirthFuture];
  }
  return undefined;
};

export const validateShippingDate = value => {
  const err = validate.single(value, {
    presence: { message: 'Enter Send Date', allowEmpty: false },
  });
  if (err) {
    return err;
  }
  const valStr = value.toString();
  const shippingDate = dayjs(valStr).format('MM/DD/YYYY');
  if (!dayjs(shippingDate).isValid()) {
    return [validationMessages.dateOfBirth];
  }
  const today = dayjs().format('MM/DD/YYYY');
  if (dayjs(shippingDate).isBefore(dayjs(today))) {
    return [validationMessages.shippingDatePast];
  }
  return undefined;
};

export const validateZipcode = (value, props, name) => {
  let valStr = String(value);
  valStr = valStr && valStr.trim();
  const pattern = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
  let labelText;
  if (props && props.children) {
    labelText = getLabelText(props.children, name);
  }
  return validate.single(valStr, {
    presence: { message: presenceErrorMsg(labelText, name), allowEmpty: false },
    format: {
      pattern,
      message: validationMessages.zipcode,
    },
  });
};

export const validatePhoneNumber = (value, allValues, props, name) => {
  const pattern = PHONE_REGEX;
  const { nameLabelMap } = props;
  const labelText = getLabelText(props.children, name);
  return validate.single(value, {
    presence: {
      message: presenceErrorMsg(
        labelText,
        nameLabelMap && nameLabelMap[name] ? nameLabelMap[name] : name,
      ),
      allowEmpty: false,
    },
    format: {
      pattern,
      message: validationMessages.phone,
    },
  });
};

export const validateCreditCard = (value, allValues, props, name) => {
  const labelText = getLabelText(props.children, name);

  let correctedValue;
  if (value) {
    correctedValue = value.replace(/ /g, '');
  }

  return validate.single(correctedValue, {
    presence: { message: presenceErrorMsg(labelText, name), allowEmpty: false },
    length: {
      maximum: 16,
      message: 'must be at most 16 characters',
    },
  });
};

export const validateCreditCardCVV = (value, allValues, props, name) => {
  const labelText = getLabelText(props.children, name);
  return validate.single(value, {
    presence: { message: presenceErrorMsg(labelText, name), allowEmpty: false },
    numericality: {
      onlyInteger: true,
    },
    length: {
      minimum: 3,
      maximum: 4,
    },
  });
};

export const validateCreditCardExp = (value, allValues, props, name) => {
  const pattern = /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/;
  const labelText = getLabelText(props.children, name);
  return validate.single(value, {
    presence: { message: presenceErrorMsg(labelText, name), allowEmpty: false },
    format: {
      pattern,
      message: validationMessages.expiry,
    },
  });
};

export const validationMessages = {
  dateOfBirth: 'The birthdate is not a valid date',
  dateOfBirthTooOld: 'Enter valid year',
  dateOfBirthFuture: 'DOB cannot be in the future',
  dateOfBirthNotOldEnough: `You must be at least ${
    Config.eligibilityAge
  } years of age`,
  email: 'is not a valid email',
  zipcode: 'Enter Zip',
  phone: 'Please type in a valid phone number!',
  passwordsDifferent: 'passwords do not match',
  invalidMonth: 'Please select a valid month',
  pastMonth: 'Expiry should not be in the past',
  expiry: 'Enter a valid expiry date',
  shippingDatePast: 'Date cannot be in the past',
};

export const isValidPhone = phone => PHONE_REGEX.test(phone);
