/*
 *
 * Preferences constants
 *
 */

export const FETCH_PREFERENCES = 'app/Preferences/FETCH_PREFERENCES';
export const FETCH_PREFERENCES_SUCCESS =
  'app/Preferences/FETCH_PREFERENCES_SUCCESS';
export const FETCH_PREFERENCES_FAILURE =
  'app/Preferences/FETCH_PREFERENCES_FAILURE';

export const UPDATE_PREFERENCES = 'app/Preferences/UPDATE_PREFERENCES';
export const UPDATE_PREFERENCES_SUCCESS =
  'app/Preferences/UPDATE_PREFERENCES_SUCCESS';
export const UPDATE_PREFERENCES_FAILURE =
  'app/Preferences/UPDATE_PREFERENCES_FAILURE';
export const RESET_UPDATED_PREFERENCES =
  'app/Preferences/UPDATE_PREFERENCES_FAILURE';

export const [
  FETCHING_PREFERENCES,
  UPDATING_PREFERENCES,
  UPDATED_PREFERENCES,
  PREFERENCES,
] = [
  'fetchingPreferences',
  'updatingPreferences',
  'updatedPreferences',
  'preferences',
];
