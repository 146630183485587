import { createSelector } from 'reselect';

/**
 * Direct selector to the appointment state domain
 */

const selectAppointmentDomain = state => state.get('appointment');

/**
 * Other specific selectors
 */

/**
 * Default selector used by Appointment
 */

const makeSelectAppointment = () =>
  createSelector(selectAppointmentDomain, appointment => appointment.toJS());

export default makeSelectAppointment;
export { selectAppointmentDomain };
