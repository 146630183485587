import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';
import { onFormSubmitPromise } from 'utils/common';
import injectSaga from 'utils/injectSaga';
import { validateEmail, validateZipcode } from 'utils/validators';
import { submitKlaviyoSubscription } from './actions';
import sagas from './sagas';

class KlaviyoList extends Component {
  constructor() {
    super();
    this.state = {
      submitSuccess: false,
      errors: [],
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.renderInputField = this.renderInputField.bind(this);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  onSubmit(values, dispatch, props) {
    this.setState({
      errors: [],
    });
    const valuesObj = values.toJS();
    const email = valuesObj.email && valuesObj.email.trim();
    const zip = valuesObj.zip && valuesObj.zip.trim();
    return onFormSubmitPromise(
      dispatch,
      submitKlaviyoSubscription({
        email,
        zip,
      }),
      props.history,
    );
  }

  handleFormChange = () => {
    if (this.state.submitSuccess) {
      this.setState({
        submitSuccess: false,
      });
    }
  };

  renderSuccess = () => {
    const { error, submitFailed, submitSucceeded } = this.props;
    if (!error && !submitFailed && submitSucceeded) {
      this.props.reset();
      this.setState({
        submitSuccess: true,
      });
    }
  };

  renderErrors = () => {
    const { error, submitFailed, valid, type } = this.props;
    const { errors } = this.state;
    if (submitFailed && error) {
      const errors = [];
      errors.push(
        <div className="form-error" key="_error">
          {`${error}`}
        </div>,
      );

      return (
        <div key="form-error" className="error-message">
          Sorry, that didn't work
        </div>
      );
    } else if (isEmpty(errors) && !valid && type === 'footer') {
      if (errors.length === 0) return null;
      return (
        <div className={`error-message error-message-${type}`}>
          OOPS! MISSING INFO
        </div>
      );
    }
    return null;
  };
  renderInputField(field) {
    if (!field) return;
    const {
      labelText,
      meta: { touched, error, form },
      input: { name, ...inputFields },
      inputType,
      spellCheck,
      inputClass,
      inFieldSuccess,
      maxlength,
      ...otherInputAttributes
    } = field;
    const otherInpAttributes = { ...otherInputAttributes };
    const { submitSucceeded, type } = this.props;
    delete otherInpAttributes.dispatch;
    const fieldId = `${form}.${name}`;
    const containerClass =
      !submitSucceeded && touched && error && error[0] ? 'error' : '';
    let placeholder;
    if (this.state.submitSuccess && !(touched && error && error[0])) {
      if (inFieldSuccess) {
        placeholder = 'Thank You!';
      }
    } else if (touched && error && error[0]) {
      this.setState({
        errors: [...this.state.errors, error[0]],
      });
      placeholder = error[0];
    } else {
      placeholder = otherInpAttributes.placeholder;
    }
    // eslint-disable-next-line consistent-return
    return (
      <div className={containerClass}>
        <input
          autoComplete="new-password"
          id={fieldId}
          type={inputType}
          name={name}
          className={inputClass}
          placeholder={placeholder}
          {...inputFields}
          maxLength={maxlength}
          spellCheck={spellCheck || 'false'}
        />
        {type !== 'footer' && touched && error && error[0] ? (
          <div className="error-message">{error[0]}</div>
        ) : (
          ''
        )}
      </div>
    );
  }
  render() {
    const {
      handleSubmit,
      type,
      submitSucceeded,
      submitting,
      ShowinFooter,
      onCloseModal,
    } = this.props;
    if (type === 'locations' && submitSucceeded) {
      return (
        <div className={`newsletter-${type}__form-wrap`}>
          <div className="newsletter-locations__desc">
            We'll contact you as soon as we're in your area!
          </div>
        </div>
      );
    }
    if (type === 'franchise' && submitSucceeded) {
      return (
        <div className={`newsletter-${type}__form-wrap`}>
          <div className="mobile-heading marginBottomStripes">Thank you</div>
          <div>
            <button
              className="btn--secondary btn outline-btn w-150"
              onClick={onCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      );
    }
    return (
      <div
        className={
          ShowinFooter
            ? 'hide-phone notify-form-main-wrap'
            : 'notify-form-main-wrap'
        }
      >
        <div className={`newsletter-${type}__form-wrap`}>
          {type === 'locations' && (
            <div className="newsletter-locations__desc">
              WE’LL LET YOU KNOW WHEN WE OPEN NEARBY!
            </div>
          )}
          <div id={`${type}Newsletter`} className={`newsletter-${type}`}>
            <form
              onChange={this.handleFormChange}
              id={`${type}NewsletterForm`}
              className={`newsletter-${type}__form notify-form-wrap`}
              onSubmit={handleSubmit((values, dispatch, props) =>
                this.onSubmit(values, dispatch, {
                  ...props,
                  history: this.props.history,
                }),
              )}
            >
              <div className={`newsletter-${type}__form-input-row`}>
                <Field
                  id="footerNewsletterEmail"
                  inputType="email"
                  labelText="Email"
                  inputClass={`input newsletterEmail${type} newsletter-${type}__form-input form-item form-item-${type}`}
                  name="email"
                  component={this.renderInputField}
                  validate={validateEmail}
                  placeholder="Email Address"
                />
                {type !== 'franchise' && (
                  <i className="phone-only icon icon--exclamation-red error-icon error-icon--email" />
                )}
              </div>
              <div className={`newsletter-${type}__form-input-row`}>
                <Field
                  id="footerNewsletterZipCode"
                  inputType="tel"
                  labelText="Zip Code"
                  name="zip"
                  maxlength="5"
                  inputClass={`input newsletter-${type}__form-input newsletter-${type}__form-input--inline`}
                  component={this.renderInputField}
                  validate={validateZipcode}
                  inFieldSuccess
                  placeholder="Zip Code"
                />
                <div className={`newsletter-${type}__form-submit hide-notify`}>
                  <button
                    id="footerNewsletterSubmit-1"
                    className={`btn newsletter-${type}__form-btn newsletter-${type}__form-btn--inline btn--secondary ${
                      submitting ? 'is-loading' : ''
                    }`}
                    type="submit"
                    name="button"
                  >
                    {type === 'locations' && (
                      <span className="btn__label">SIGN UP</span>
                    )}
                    {type === 'footer' && (
                      <span className="btn__label">SUBMIT</span>
                    )}
                    {type === 'franchise' && (
                      <span className="btn__label"> NOTIFY ME</span>
                    )}
                  </button>
                </div>
              </div>
              <div className={`newsletter-${type}__form-submit show-notify`}>
                <button
                  id="footerNewsletterSubmit-2"
                  className={`btn newsletter-${type}__form-btn newsletter-${type}__form-btn--inline btn--secondary ${
                    submitting ? 'is-loading' : ''
                  }`}
                  type="submit"
                  name="button"
                >
                  {type === 'locations' && (
                    <span className="btn__label">SIGN UP</span>
                  )}
                  {type === 'footer' && (
                    <span className="btn__label">SUBMIT</span>
                  )}
                  {type === 'franchise' && (
                    <span className="btn__label"> NOTIFY ME</span>
                  )}
                </button>
              </div>
            </form>
            {this.renderErrors()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    );
  }
}
KlaviyoList = reduxForm({
  form: 'KlaviyoListForm',
})(KlaviyoList);
const withConnect = connect(
  null,
  { submitKlaviyoSubscription },
);
const withSagaKlaviyoSubscription = injectSaga({
  key: 'klaviyoSub',
  saga: sagas.watchKlaviyoSubscription,
});
KlaviyoList.propTypes = {
  ShowinFooter: PropTypes.bool,
  onCloseModal: PropTypes.func,
};
export default compose(
  withConnect,
  withSagaKlaviyoSubscription,
)(KlaviyoList);
