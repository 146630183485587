/*
 *
 * Appointment reducer
 *
 */

import { fromJS } from 'immutable';
import {
  APPLY_REFERRAL,
  APPLY_REFERRAL_SUCCESS,
  CANCEL_USER_APPOINTMENT,
  CANCEL_USER_APPOINTMENT_FAILURE,
  CANCEL_USER_APPOINTMENT_SUCCESS,
  CHECK_GIFT_CODE,
  CHECK_GIFT_CODE_FAILURE,
  CHECK_GIFT_CODE_SUCCESS,
  CLEAR_APPOINTMENT_BOOKING_DATA,
  CLEAR_APPOINTMENT_NET_VALUE,
  CLEAR_CURRENT_SELECTED_DATE,
  CLEAR_CURRENT_THERAPIST_CLICKED,
  CLEAR_ENTERED_PHONE_NUMBER,
  CLEAR_ENTERED_PROMO_CODE,
  CLEAR_FILTER_STATE,
  CLEAR_GIFT_CODE,
  CLEAR_NEAREST_LOCATIONS,
  CLEAR_SELECTED_APPOINTMENT_SLOT,
  CLEAR_SELECTED_LOCATION,
  CLEAR_SELECTED_MASSAGE_TYPE,
  CLEAR_SELECTED_SERVICE,
  CREATE_USER_APPOINTMENT,
  CREATE_USER_APPOINTMENT_FAILURE,
  CREATE_USER_APPOINTMENT_SUCCESS,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENTS_FAILURE,
  FETCH_APPOINTMENTS_SUCCESS,
  FETCH_AVAILABLE_THERAPISTS,
  FETCH_AVAILABLE_THERAPISTS_FAILURE,
  FETCH_AVAILABLE_THERAPISTS_SUCCESS,
  FETCH_LOCATIONS,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_SUCCESS,
  FETCH_LOCATION_MORE_INFO,
  FETCH_LOCATION_MORE_INFO_FAILURE,
  FETCH_LOCATION_MORE_INFO_SUCCESS,
  FETCH_LOCATION_SERVICES,
  FETCH_LOCATION_SERVICES_FAILURE,
  FETCH_LOCATION_SERVICES_SUCCESS,
  FETCH_MASSAGE_TYPES,
  FETCH_MASSAGE_TYPES_FAILURE,
  FETCH_MASSAGE_TYPES_SUCCESS,
  FETCH_NEAREST_LOCATION,
  FETCH_NEAREST_LOCATION_FAILURE,
  FETCH_NEAREST_LOCATION_SUCCESS,
  FETCH_STATUS,
  FETCH_STATUS_FAILURE,
  FETCH_STATUS_SUCCESS,
  FETCH_USER_COMPLETED_APPOINTMENTS,
  FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE,
  FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS,
  FETCH_USER_PRESSURE,
  FETCH_USER_PRESSURE_FAILURE,
  FETCH_USER_PRESSURE_SUCCESS,
  GET_LIABILITY_WAIVER,
  GET_LIABILITY_WAIVER_FAILURE,
  GET_LIABILITY_WAIVER_SUCCESS,
  RETRY_APPOINTMENT_CHARGE,
  RETRY_APPOINTMENT_CHARGE_FAILURE,
  RETRY_APPOINTMENT_CHARGE_SUCCESS,
  SEND_SIGNATURE,
  SEND_SIGNATURE_FAILURE,
  SEND_SIGNATURE_SUCCESS,
  SET_APPOINTMENT_NET_VALUE,
  SET_BOOKING_FLOW,
  SET_CURRENT_SELECTED_DATE,
  SET_CURRENT_THERAPIST_CLICKED,
  SET_ENTERED_PHONE_NUMBER,
  SET_ENTERED_PROMO_CODE,
  SET_FILTER_STATE,
  SET_NOT_AVAILABILE_TILL_DATE,
  SET_SELECTED_APPOINTMENT_SLOT,
  SET_SELECTED_LOCATION,
  SET_SELECTED_MASSAGE_TYPE,
  SET_SELECTED_SERVICE,
  UPDATE_APPOINTMENT_TEXT,
  UPDATE_APPOINTMENT_TEXT_ERROR,
  UPDATE_APPOINTMENT_TEXT_SUCCESS,
  UPDATE_USER_PRESSURE,
  UPDATE_USER_PRESSURE_FAILURE,
  UPDATE_USER_PRESSURE_SUCCESS,
} from './constants';

export const initialState = fromJS({
  locations: [],
  fetchingLocations: false,
  fetchingLocationsError: null,
  selectedLocation: {},
  fetchingLocationServices: false,
  fetchingLocationServicesError: false,
  locationServices: {},
  selectedService: {},
  massageTypes: [],
  fetchingMassageTypes: false,
  fetchingMassageTypesError: null,
  selectedMassageType: {},
  availableSlots: [],
  fetchingAvailableSlots: false,
  fetchingAvailableSlotsError: null,
  selectedAppointmentSlot: {},
  giftCodeResult: {},
  checkingGiftCode: false,
  checkingGiftCodeError: null,
  createUserAppointmentResult: {},
  creatingUserAppointment: false,
  creatingUserAppointmentError: null,
  pressurePreferences: [],
  fetchingPressurePreferences: false,
  fetchingPressurePreferencesError: null,
  bookingFlow: null,
  fetchingUserCompletedAppointments: false,
  fetchingUSerAppointmentsError: null,
  userCompletedAppointments: [],
  fetchingAppointments: false,
  fetchingAppointmentsError: null,
  myAppointments: [],
  cancellingUserAppointment: false,
  cancellingUserAppointmentError: null,
  cancelUserAppointmentResult: {},
  updatingAppointmentText: false,
  updatingAppointmentTextError: null,
  appointmentText: {},
  filterState: {},
  currentTherapistClicked: {},
  currentSelectedDate: null,
  enteredPhoneNumber: null,
  appointmentNetValue: null,
  enteredPromoCode: null,
  retryingAppointmentCharge: false,
  retryingAppointmentChargeError: null,
  retryAppointmentChargeResult: {},
  applyReferral: false,
  referralResult: null,
  fetchingStatus: false,
  fetchingStatusError: null,
  myStatus: [],
  updatingPressurePreferences: false,
  updatingPressurePreferencesError: null,
  updatePressurePreferencesResult: {},
  notAvailableTillDate: null,
  nearestLocations: [],
  fetchingNearestLocations: false,
  fetchingNearestLocationsError: null,
  liabilityWaiver: [],
  fetchingLiabilityWaiver: false,
  fetchingLiabilityWaiverError: null,
  signature: [],
  sendingSignature: false,
  sendingSignatureError: null,
});

function appointmentReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return state.withMutations(s => {
        s.set('fetchingLocations', true)
          .set('fetchingLocationsError', null)
          .set('locations', fromJS([]));
      });

    case FETCH_LOCATIONS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingLocations', false)
          .set('fetchingLocationsError', null)
          .set('locations', fromJS(action.result));
      });

    case FETCH_LOCATIONS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingLocations', false)
          .set('fetchingLocationsError', action.error)
          .set('locations', fromJS([]));
      });

    case SET_SELECTED_LOCATION:
      return state.withMutations(s => {
        s.set('selectedLocation', fromJS(action.location));
      });

    case FETCH_LOCATION_SERVICES:
      return state.withMutations(s => {
        s.set('fetchingLocationServices', true)
          .set('fetchingLocationServicesError', null)
          .set('locationServices', fromJS({}));
      });

    case FETCH_LOCATION_SERVICES_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingLocationServices', false)
          .set('fetchingLocationServicesError', null)
          .set('locationServices', fromJS(action.result));
      });

    case FETCH_LOCATION_SERVICES_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingLocationServices', false)
          .set('fetchingLocationServicesError', action.error)
          .set('locationServices', fromJS({}));
      });

    case FETCH_LOCATION_MORE_INFO:
      return state.withMutations(s => {
        s.set('fetchingLocationMoreInfo', true)
          .set('fetchingLocationMoreInfoError', null)
          .set('locationMoreInfo', fromJS({}));
      });

    case FETCH_LOCATION_MORE_INFO_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingLocationMoreInfo', false)
          .set('fetchingLocationMoreInfoError', null)
          .set('locationMoreInfo', fromJS(action.result));
      });

    case FETCH_LOCATION_MORE_INFO_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingLocationMoreInfo', false)
          .set('fetchingLocationMoreInfoError', action.error)
          .set('locationServices', fromJS({}));
      });

    case SET_SELECTED_SERVICE:
      return state.withMutations(s => {
        s.set('selectedService', fromJS(action.service));
      });

    case FETCH_MASSAGE_TYPES:
      return state.withMutations(s => {
        s.set('fetchingMassageTypes', true)
          .set('fetchingMassageTypesError', null)
          .set('massageTypes', fromJS([]));
      });

    case FETCH_MASSAGE_TYPES_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingMassageTypes', false)
          .set('fetchingMassageTypesError', null)
          .set('massageTypes', fromJS(action.result));
      });

    case FETCH_MASSAGE_TYPES_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingMassageTypes', false)
          .set('fetchingMassageTypesError', action.error)
          .set('massageTypes', fromJS([]));
      });

    case SET_SELECTED_MASSAGE_TYPE:
      return state.withMutations(s => {
        s.set('selectedMassageType', fromJS(action.massageType));
      });

    case FETCH_AVAILABLE_THERAPISTS:
      return state.withMutations(s => {
        s.set('fetchingAvailableSlots', true)
          .set('fetchingAvailableSlotsError', null)
          .set('availableSlots', fromJS([]));
      });

    case FETCH_AVAILABLE_THERAPISTS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingAvailableSlots', false)
          .set('fetchingAvailableSlotsError', null)
          .set('availableSlots', fromJS(action.result));
      });

    case FETCH_AVAILABLE_THERAPISTS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingAvailableSlots', false)
          .set('fetchingAvailableSlotsError', action.error)
          .set('availableSlots', fromJS([]));
      });

    case SET_NOT_AVAILABILE_TILL_DATE:
      return state.withMutations(s => {
        s.set('notAvailableTillDate', fromJS(action.result));
      });

    case SET_SELECTED_APPOINTMENT_SLOT:
      return state.withMutations(s => {
        s.set('selectedAppointmentSlot', fromJS(action.appointmentSlot));
      });

    case CHECK_GIFT_CODE:
      return state.withMutations(s => {
        s.set('checkingGiftCode', true)
          .set('checkingGiftCodeError', null)
          .set('giftCodeResult', fromJS({}));
      });

    case CHECK_GIFT_CODE_SUCCESS:
      return state.withMutations(s => {
        s.set('checkingGiftCode', false)
          .set('checkingGiftCodeError', null)
          .set('giftCodeResult', fromJS(action.result));
      });

    case CHECK_GIFT_CODE_FAILURE:
      return state.withMutations(s => {
        s.set('checkingGiftCode', false)
          .set('checkingGiftCodeError', action.error)
          .set('giftCodeResult', fromJS({}));
      });

    case CLEAR_GIFT_CODE:
      return state.withMutations(s => {
        s.set('checkingGiftCode', false)
          .set('checkingGiftCodeError', null)
          .set('giftCodeResult', fromJS({}));
      });

    case CREATE_USER_APPOINTMENT:
      return state.withMutations(s => {
        s.set('creatingUserAppointment', true)
          .set('creatingUserAppointmentError', null)
          .set('createUserAppointmentResult', fromJS({}));
      });

    case CREATE_USER_APPOINTMENT_SUCCESS:
      return state.withMutations(s => {
        s.set('creatingUserAppointment', false)
          .set('creatingUserAppointmentError', null)
          .set('createUserAppointmentResult', fromJS(action.result));
      });

    case CREATE_USER_APPOINTMENT_FAILURE:
      return state.withMutations(s => {
        s.set('creatingUserAppointment', false)
          .set('creatingUserAppointmentError', action.error)
          .set('createUserAppointmentResult', fromJS({}));
      });

    case CLEAR_APPOINTMENT_BOOKING_DATA:
      return state.withMutations(s => {
        s.set('creatingUserAppointment', false)
          .set('creatingUserAppointmentError', null)
          .set('createUserAppointmentResult', fromJS({}));
      });

    case CLEAR_SELECTED_LOCATION:
      return state.withMutations(s => {
        s.set('selectedLocation', fromJS({}));
      });

    case CLEAR_SELECTED_SERVICE:
      return state.withMutations(s => {
        s.set('selectedService', {});
      });

    case CLEAR_SELECTED_APPOINTMENT_SLOT:
      return state.withMutations(s => {
        s.set('selectedAppointmentSlot', {});
      });

    case CLEAR_SELECTED_MASSAGE_TYPE:
      return state.withMutations(s => {
        s.set('selectedMassageType', {});
      });

    case FETCH_USER_PRESSURE:
      return state.withMutations(s => {
        s.set('fetchingPressurePreferences', true)
          .set('fetchingPressurePreferencesError', null)
          .set('pressurePreferences', fromJS([]));
      });

    case FETCH_USER_PRESSURE_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingPressurePreferences', false)
          .set('fetchingPressurePreferencesError', null)
          .set('pressurePreferences', fromJS(action.result));
      });

    case FETCH_USER_PRESSURE_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingPressurePreferences', false)
          .set('fetchingPressurePreferencesError', action.error)
          .set('pressurePreferences', fromJS([]));
      });

    case UPDATE_USER_PRESSURE:
      return state.withMutations(s => {
        s.set('updatingPressurePreferences', true)
          .set('updatingPressurePreferencesError', null)
          .set('pressurePreferences', fromJS([]));
      });

    case UPDATE_USER_PRESSURE_SUCCESS:
      return state.withMutations(s => {
        s.set('updatingPressurePreferences', false)
          .set('updatingPressurePreferencesError', null)
          .set('pressurePreferences', fromJS(action.result));
      });

    case UPDATE_USER_PRESSURE_FAILURE:
      return state.withMutations(s => {
        s.set('updatingPressurePreferences', false)
          .set('updatingPressurePreferencesError', action.error)
          .set('pressurePreferences', fromJS([]));
      });

    case UPDATE_APPOINTMENT_TEXT:
      return state.withMutations(s => {
        s.set('updatingAppointmentText', true)
          .set('updatingAppointmentTextError', null)
          .set('appointmentText', fromJS({}));
      });

    case UPDATE_APPOINTMENT_TEXT_SUCCESS:
      return state.withMutations(s => {
        s.set('updatingAppointmentText', false)
          .set('updatingAppointmentTextError', null)
          .set('appointmentText', fromJS(action.result));
      });

    case UPDATE_APPOINTMENT_TEXT_ERROR:
      return state.withMutations(s => {
        s.set('updatingAppointmentText', false)
          .set('updatingAppointmentTextError', action.error)
          .set('appointmentText', fromJS({}));
      });

    case SET_BOOKING_FLOW:
      return state.withMutations(s => {
        s.set('bookingFlow', action.flowType);
      });

    case FETCH_USER_COMPLETED_APPOINTMENTS:
      return state.withMutations(s => {
        s.set('fetchingUserCompletedAppointments', true)
          .set('fetchingUserCompletedAppointmentsError', null)
          .set('userCompletedAppointments', fromJS([]));
      });

    case FETCH_USER_COMPLETED_APPOINTMENTS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingUserCompletedAppointments', false)
          .set('fetchingUserCompletedAppointmentsError', null)
          .set('userCompletedAppointments', fromJS(action.result));
      });

    case FETCH_USER_COMPLETED_APPOINTMENTS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingUserCompletedAppointments', false)
          .set('fetchingUserCompletedAppointmentsError', action.error)
          .set('userCompletedAppointments', fromJS([]));
      });

    // My Appointments
    case FETCH_APPOINTMENTS:
      return state.withMutations(s => {
        s.set('fetchingAppointments', true)
          .set('fetchingAppointmentsError', null)
          .set('myAppointments', fromJS([]));
      });

    case FETCH_APPOINTMENTS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingAppointments', false)
          .set('fetchingAppointmentsError', null)
          .set('myAppointments', fromJS(action.result));
      });

    case FETCH_APPOINTMENTS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingAppointments', false)
          .set('fetchingAppointmentsError', action.error)
          .set('myAppointments', fromJS([]));
      });

    // Status
    case FETCH_STATUS:
      return state.withMutations(s => {
        s.set('fetchingStatus', true)
          .set('fetchingStatusError', null)
          .set('myStatus', fromJS([]));
      });

    case FETCH_STATUS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingStatus', false)
          .set('fetchingStatusError', null)
          .set('myStatus', fromJS(action.result));
      });

    case FETCH_STATUS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingStatus', false)
          .set('fetchingStatusError', action.error)
          .set('myStatus', fromJS([]));
      });

    case CANCEL_USER_APPOINTMENT:
      return state.withMutations(s => {
        s.set('cancellingUserAppointment', true)
          .set('cancellingUserAppointmentError', null)
          .set('cancelUserAppointmentResult', fromJS({}));
      });

    case CANCEL_USER_APPOINTMENT_SUCCESS:
      return state.withMutations(s => {
        s.set('cancellingUserAppointment', false)
          .set('cancellingUserAppointmentError', null)
          .set('cancelUserAppointmentResult', fromJS(action.result));
      });

    case CANCEL_USER_APPOINTMENT_FAILURE:
      return state.withMutations(s => {
        s.set('cancellingUserAppointment', false)
          .set('cancellingUserAppointmentError', action.error)
          .set('cancelUserAppointmentResult', fromJS({}));
      });

    case SET_FILTER_STATE:
      return state.withMutations(s => {
        s.set('filterState', action.filterState);
      });

    case CLEAR_FILTER_STATE:
      return state.withMutations(s => {
        s.set('filterState', {});
      });

    case SET_CURRENT_THERAPIST_CLICKED:
      return state.withMutations(s => {
        s.set('currentTherapistClicked', action.currentTherapistClicked);
      });

    case CLEAR_CURRENT_THERAPIST_CLICKED:
      return state.withMutations(s => {
        s.set('currentTherapistClicked', {});
      });

    case SET_CURRENT_SELECTED_DATE:
      return state.withMutations(s => {
        s.set('currentSelectedDate', action.currentSelectedDate);
      });

    case CLEAR_CURRENT_SELECTED_DATE:
      return state.withMutations(s => {
        s.set('currentSelectedDate', null);
      });

    case SET_ENTERED_PHONE_NUMBER:
      return state.withMutations(s => {
        s.set('enteredPhoneNumber', action.enteredPhoneNumber);
      });

    case CLEAR_ENTERED_PHONE_NUMBER:
      return state.withMutations(s => {
        s.set('enteredPhoneNumber', null);
      });

    case SET_APPOINTMENT_NET_VALUE:
      return state.withMutations(s => {
        s.set('appointmentNetValue', action.appointmentNetValue);
      });

    case CLEAR_APPOINTMENT_NET_VALUE:
      return state.withMutations(s => {
        s.set('appointmentNetValue', null);
      });

    case SET_ENTERED_PROMO_CODE:
      return state.withMutations(s => {
        s.set('enteredPromoCode', action.enteredPromoCode);
      });

    case CLEAR_ENTERED_PROMO_CODE:
      return state.withMutations(s => {
        s.set('enteredPromoCode', null);
      });

    case RETRY_APPOINTMENT_CHARGE:
      return state.withMutations(s => {
        s.set('retryingAppointmentCharge', true)
          .set('retryingAppointmentChargeError', null)
          .set('retryAppointmentChargeResult', fromJS({}));
      });

    case RETRY_APPOINTMENT_CHARGE_SUCCESS:
      return state.withMutations(s => {
        s.set('retryingAppointmentCharge', false)
          .set('retryingAppointmentChargeError', null)
          .set('retryAppointmentChargeResult', fromJS(action.result));
      });

    case RETRY_APPOINTMENT_CHARGE_FAILURE:
      return state.withMutations(s => {
        s.set('retryingAppointmentCharge', false)
          .set('retryingAppointmentChargeError', action.error)
          .set('retryAppointmentChargeResult', fromJS({}));
      });

    case APPLY_REFERRAL:
      return state.withMutations(s => {
        s.set('applyReferral', true);
      });

    case APPLY_REFERRAL_SUCCESS:
      return state.withMutations(s => {
        s.set('applyReferral', false);
        s.set('referralResult', action.result);
      });

    case FETCH_NEAREST_LOCATION:
      return state.withMutations(s => {
        s.set('fetchingNearestLocations', true)
          .set('fetchingNearestLocationsError', null)
          .set('nearestLocations', fromJS({}));
      });

    case FETCH_NEAREST_LOCATION_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingNearestLocations', false)
          .set('fetchingNearestLocationsError', null)
          .set('nearestLocations', fromJS(action.result));
      });

    case FETCH_NEAREST_LOCATION_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingNearestLocations', false)
          .set('fetchingNearestLocationsError', action.error)
          .set('nearestLocations', fromJS({}));
      });

    case CLEAR_NEAREST_LOCATIONS:
      return state.withMutations(s => {
        s.set('nearestLocations', {});
      });

    case GET_LIABILITY_WAIVER:
      return state.withMutations(s => {
        s.set('fetchingLiabilityWaiver', true)
          .set('fetchingLiabilityWaiverError', null)
          .set('liabilityWaiver', fromJS({}));
      });

    case GET_LIABILITY_WAIVER_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingLiabilityWaiver', false)
          .set('fetchingLiabilityWaiverError', null)
          .set('liabilityWaiver', fromJS(action.result));
      });

    case GET_LIABILITY_WAIVER_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingLiabilityWaiver', false)
          .set('fetchingLiabilityWaiverError', action.error)
          .set('liabilityWaiver', fromJS({}));
      });

    case SEND_SIGNATURE:
      return state.withMutations(s => {
        s.set('sendingSignature', true)
          .set('sendingSignatureError', null)
          .set('signature', fromJS({}));
      });

    case SEND_SIGNATURE_SUCCESS:
      return state.withMutations(s => {
        s.set('sendingSignature', false)
          .set('sendingSignatureError', null)
          .set('signature', fromJS(action.result));
      });

    case SEND_SIGNATURE_FAILURE:
      return state.withMutations(s => {
        s.set('sendingSignature', false)
          .set('sendingSignatureError', action.error)
          .set('signature', fromJS({}));
      });

    case 'RESET_ACTION':
      return initialState;

    default:
      return state;
  }
}

export default appointmentReducer;
