export const SET_SELECTED_GIFT_TYPE = 'app/Gifting/SET_SELECTED_GIFT_TYPE';
export const SET_SELECTED_GIFT_SUB_TYPE =
  'app/Gifting/SET_SELECTED_GIFT_SUB_TYPE';
export const SET_SELECTED_GIFT_SUB_TYPE_QUANTITY =
  'app/Gifting/SET_SELECTED_GIFT_SUB_TYPE_QUANTITY';
export const SET_SERVICES = 'app/Gifting/SET_SERVICES';
export const SET_MEMBERSHIP_DURATION = 'app/Gifting/SET_MEMBERSHIP_DURATION';
export const SET_AMOUNT = 'app/Gifting/SET_AMOUNT';
export const SET_SHIPPING_DETAILS = 'app/Gifting/SET_SHIPPING_DETAILS';

export const PURCHASE_GIFT = 'app/Gift/PURCHASE_GIFT';
export const PURCHASE_GIFT_SUCCESS = 'app/Gift/PURCHASE_GIFT_SUCCESS';
export const PURCHASE_GIFT_FAILURE = 'app/Gift/PURCHASE_GIFT_FAILURE';

export const CLEAR_GIFT_DATA = 'app/Gift/CLEAR_GIFT_DATA';
export const CLEAR_GIFT_PURCHASE_DATA =
  'app/Appointments/CLEAR_GIFT_PURCHASE_DATA';

export const SET_GIFT_TOTAL = 'app/Gift/SET_GIFT_TOTAL';
