import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the membership state domain
 */

const selectMembershipDomain = state => state.get('membership', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by Membership
 */

const makeSelectMembership = () =>
  createSelector(selectMembershipDomain, membership => membership.toJS());

export default makeSelectMembership;
export { selectMembershipDomain };
