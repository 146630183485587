/*
 *
 * Membership constants
 *
 */

export const FETCH_MEMBERSHIP_DETAILS =
  'app/Membership/FETCH_MEMBERSHIP_DETAILS';
export const FETCH_MEMBERSHIP_DETAILS_SUCCESS =
  'app/Membership/FETCH_MEMBERSHIP_DETAILS_SUCCESS';
export const FETCH_MEMBERSHIP_DETAILS_FAILURE =
  'app/Membership/FETCH_MEMBERSHIP_DETAILS_FAILURE';

export const SET_SELECTED_MEMBERSHIP_TYPE =
  'app/Membership/SET_SELECTED_MEMBERSHIP_TYPE';

export const PURCHASE_MEMBERSHIP = 'app/Membership/PURCHASE_MEMBERSHIP';
export const PURCHASE_MEMBERSHIP_SUCCESS =
  'app/Membership/PURCHASE_MEMBERSHIP_SUCCESS';
export const PURCHASE_MEMBERSHIP_FAILURE =
  'app/Membership/PURCHASE_MEMBERSHIP_FAILURE';

export const SET_HOME_LOCATION = 'app/Membership/SET_HOME_LOCATION';
export const CLEAR_HOME_LOCATION = 'app/Membership/CLEAR_HOME_LOCATION';

export const CANCEL_MEMBERSHIP = 'app/Membership/CANCEL_MEMBERSHIP';
export const CANCEL_MEMBERSHIP_SUCCESS =
  'app/Membership/CANCEL_MEMBERSHIP_SUCCESS';
export const CANCEL_MEMBERSHIP_FAILURE =
  'app/Membership/CANCEL_MEMBERSHIP_FAILURE';

export const PAUSE_MEMBERSHIP = 'app/Membership/PAUSE_MEMBERSHIP';
export const PAUSE_MEMBERSHIP_SUCCESS =
  'app/Membership/PAUSE_MEMBERSHIP_SUCCESS';
export const PAUSE_MEMBERSHIP_FAILURE =
  'app/Membership/PAUSE_MEMBERSHIP_FAILURE';

export const RESUME_MEMBERSHIP = 'app/Membership/RESUME_MEMBERSHIP';
export const RESUME_MEMBERSHIP_SUCCESS =
  'app/Membership/RESUME_MEMBERSHIP_SUCCESS';
export const RESUME_MEMBERSHIP_FAILURE =
  'app/Membership/RESUME_MEMBERSHIP_FAILURE';

export const CLEAR_MEMBERSHIP_PURCHASE_DATA =
  'app/Membership/CLEAR_MEMBERSHIP_PURCHASE_DATA';

export const SUBMIT_MEMBERSHIP_CANCELLATION_REASONS =
  'app/Membership/SUBMIT_MEMBERSHIP_CANCELLATION_REASONS';
export const SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS =
  'app/Membership/SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS';
export const SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE =
  'app/Membership/SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE';

export const CLEAR_MEMBERSHIP_DATA = 'app/Membership/CLEAR_MEMBERSHIP_DATA';
export const CLEAR_MEMBERSHIP_ERROR = 'CLEAR_MEMBERSHIP_ERROR';
