import { get } from 'lodash-es';

export const localStorageMiddleware = ({ getState }) => next => action => {
  const result = next(action);
  try {
    const state = getState().toJS();
    localStorage.setItem(
      'applicationState',
      JSON.stringify({
        appointment: get(state, 'appointment'),
        therapist: get(state, 'therapist'),
        user: get(state, 'user'),
        membership: get(state, 'membership'),
        gift: get(state, 'gift'),
        preferences: get(state, 'preferences'),
      }),
    );
  } catch (e) {
    // ignore for now
  }
  return result;
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('applicationState');
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};
