import { fromJS } from 'immutable';
import {
  GET_GIFT_DETAILS,
  GET_GIFT_DETAILS_SUCCESS,
  GET_GIFT_DETAILS_FAILURE,
} from './constants';
const initialState = fromJS({
  giftDetailsFailed: false,
  giftDetailsSuccess: null,
  giftDetails: [],
});

function printingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GIFT_DETAILS_SUCCESS:
      return state.withMutations(s => {
        s.set('giftDetailsFailed', false)
          .set('giftDetailsSuccess', true)
          .set('giftDetails', fromJS([action.result]));
      });

    case GET_GIFT_DETAILS_FAILURE:
      return state.withMutations(s => {
        s.set('giftDetailsFailed', true).set('giftDetailsSuccess', false);
        // .set('purchaseGiftResult', fromJS(action.result));
      });

    default:
      return state;
  }
}

export default printingReducer;
