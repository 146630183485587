import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import Header from '../../containers/Header';
import Footer from '../../containers/Footer';
import SectionContainer from '../SectionContainer';
import PageHeading from '../PageHeading';
import { analytics } from '../../utils/common';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true }, () => this.props.history.push('/'));
    // Send error to Sentry
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
        scope.setExtra('Email', localStorage.getItem('email'));
        scope.setExtra('UserID', localStorage.getItem('userId'));
      });
      Sentry.captureException(error);
    });
  }

  renderErrorContent() {
    analytics('404 Shiatsu - Something Went Wrong');
    return (
      <Fragment>
        <div className="appWrap">
          <div className="MainContentWrap">
            <Header />
            <div className="footerMarginContainer">
              <PageHeading mobileTitle="Sorry, Something went wrong!">
                Sorry, Something went wrong!
              </PageHeading>
              <div className="exit-booking__content">
                <div className="exit-booking__stripes subhdr-stripes subhdr-stripes--sub subhdr-stripes--ucase hide-phone">
                  <span className="stripes-bg" />
                </div>
                <div className="exit-booking__content-wrap">
                  <div className="exit-booking__bubble">
                    <div className="bubble">
                      <p className="exit-booking__bubble-text">Oh Shiatsu!</p>
                    </div>
                    <i className="icon icon--frown-reflection" />
                  </div>
                </div>
              </div>
              <SectionContainer />
            </div>
          </div>
          <div className="FootContentWrap">
            <Footer showItems={false} />
          </div>
        </div>
      </Fragment>
    );
  }

  render() {
    if (this.state.hasError) {
      return this.renderErrorContent();
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object,
};

export default compose(withRouter)(ErrorBoundary);
