import { fromJS } from 'immutable';

import {
  GET_INVITE_LINK_FAILURE,
  GET_INVITE_LINK_SUCCESS,
  INVITE_FAILURE,
  INVITE_SUCCESS,
  SEND_INVITE,
} from './constants';

const initialState = fromJS({});

function inviteReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_INVITE:
      return state.withMutations(s => {
        s.set('inviteSuccess', false).set('error', false);
      });
    case INVITE_SUCCESS:
      return state.withMutations(s => {
        s.set('inviteSuccess', true);
      });
    case INVITE_FAILURE:
      return state.withMutations(s => {
        s.set('error', action.error);
      });
    case GET_INVITE_LINK_SUCCESS:
      return state.withMutations(s => {
        s.set('link', action.result);
      });
    case GET_INVITE_LINK_FAILURE:
      return state.withMutations(s => {
        s.set('error', true);
      });
    default:
      return state;
  }
}

export default inviteReducer;
