/*
 *
 * Therapist constants
 *
 */

export const FETCH_THERAPISTS = 'app/Therapist/FETCH_THERAPISTS';
export const FETCH_THERAPISTS_SUCCESS =
  'app/Therapist/FETCH_THERAPISTS_SUCCESS';
export const FETCH_THERAPISTS_FAILURE =
  'app/Therapist/FETCH_THERAPISTS_FAILURE';

export const FETCH_THERAPIST_DETAIL = 'app/Therapist/FETCH_THERAPIST_DETAIL';
export const FETCH_THERAPIST_DETAIL_SUCCESS =
  'app/Therapist/FETCH_THERAPIST_DETAIL_SUCCESS';
export const FETCH_THERAPIST_DETAIL_FAILURE =
  'app/Therapist/FETCH_THERAPIST_DETAIL_FAILURE';

export const SET_SELECTED_THERAPIST = 'app/Therapist/SET_SELECTED_THERAPIST';

export const TIP_THERAPIST = 'app/Therapist/TIP_THERAPIST';
export const TIP_THERAPIST_SUCCESS = 'app/Therapist/TIP_THERAPIST_SUCCESS';
export const TIP_THERAPIST_FAILURE = 'app/Therapist/TIP_THERAPIST_FAILURE';

export const CLEAR_TIP_THERAPIST_DATA =
  'app/Therapist/CLEAR_TIP_THERAPIST_DATA';

export const SET_TIPPED_APPOINTMENT = 'app/Therapist/SET_TIPPED_APPOINTMENT';
export const CLEAR_TIPPED_APPOINTMENT =
  'app/Therapist/CLEAR_TIPPED_APPOINTMENT';

export const RATE_THERAPIST = 'app/Therapist/RATE_THERAPIST';
export const RATE_THERAPIST_SUCCESS = 'app/Therapist/RATE_THERAPIST_SUCCESS';
export const RATE_THERAPIST_FAILURE = 'app/Therapist/RATE_THERAPIST_FAILURE';

export const FETCH_USER_THERAPISTS = 'app/Therapist/FETCH_USER_THERAPISTS';
export const FETCH_USER_THERAPISTS_SUCCESS =
  'app/Therapist/FETCH_USER_THERAPISTS_SUCCESS';
export const FETCH_USER_THERAPISTS_FAILURE =
  'app/Therapist/FETCH_USER_THERAPISTS_FAILURE';

export const FAVORITE_THERAPIST = 'app/Therapist/FAVORITE_THERAPIST';
export const FAVORITE_THERAPIST_SUCCESS =
  'app/Therapist/FAVORITE_THERAPIST_SUCCESS';
export const FAVORITE_THERAPIST_FAILURE =
  'app/Therapist/FAVORITE_THERAPIST_FAILURE';

export const UNFAVORITE_THERAPIST = 'app/Therapist/UNFAVORITE_THERAPIST';
export const UNFAVORITE_THERAPIST_SUCCESS =
  'app/Therapist/UNFAVORITE_THERAPIST_SUCCESS';
export const UNFAVORITE_THERAPIST_FAILURE =
  'app/Therapist/UNFAVORITE_THERAPIST_FAILURE';

export const UPDATE_PRIVATE_NOTE = 'app/Therapist/UPDATE_PRIVATE_NOTE';
export const UPDATE_PRIVATE_NOTE_SUCCESS =
  'app/Therapist/UPDATE_PRIVATE_NOTE_SUCCESS';
export const UPDATE_PRIVATE_NOTE_FAILURE =
  'app/Therapist/UPDATE_PRIVATE_NOTE_FAILURE';

export const FETCH_THERAPIST_REVIEWS = 'app/Therapist/FETCH_THERAPISTS_REVIEWS';
export const FETCH_THERAPIST_REVIEWS_SUCCESS =
  'app/Therapist/FETCH_THERAPIST_REVIEWS_SUCCESS';
export const FETCH_THERAPIST_REVIEWS_FAILURE =
  'app/Therapist/FETCH_THERAPIST_REVIEWS_FAILURE';
