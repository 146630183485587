/* export default {
  apiBase: process.env.API_BASE || 'https://staging.joinsqueeze.com/api',
  googleMapsApiKey:
    process.env.GOOGLE_MAPS_API_KEY ||
    'AIzaSyA5-ageiOZhTlth17viQoxBAZmcH8AKMTA',
  stripeApiKey:
    process.env.STRIPE_API_KEY || 'pk_test_A7dBTv79yXG23Nni03fts9Uw',
  segmentApiKey:
    process.env.SEGMENT_API_KEY || 'jmEceW2Eqc5x8r1Op12tLNBuU5lx32Nc',
  eligibilityAge: Number(process.env.ELIGIBILITY_AGE) || 13,
  minimumPurchaseAmountForGift: process.env.MINIMUM_PURCHASE_FOR_GIFT || 20,
};
 */

const Config = {
  apiBase: process.env.API_BASE,
  apiBasev2: process.env.API_BASE_V2,
  giftApiBase: process.env.GIFT_API_BASE,
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  stripeApiKey: process.env.STRIPE_API_KEY,
  stripePublicGiftApiKey: process.env.STRIPE_PUBLIC_GIFT_KEY,
  branchApiKey: process.env.BRANCH_API_KEY,
  facebookAppId: process.env.FACEBOOK_APP_ID,
  segmentApiKey: process.env.SEGMENT_API_KEY,
  eligibilityAge: Number(process.env.ELIGIBILITY_AGE) || 13,
  minimumPurchaseAmountForGift: process.env.MINIMUM_PURCHASE_FOR_GIFT || 20,
  loginDeepLink: process.env.LOGIN_DEEP_LINK,
  sentryDsn: process.env.SENTRY_DSN,
  locationNames: process.env.LOCATION_NAMES,
  scottsdale: process.env.SCOTTSDALE_WIDGET_ID,
  brentwood: process.env.BRENTWOOD_WIDGET_ID,
  cherrycreek: process.env.CHERRYCREEK_WIDGET_ID,
  alamoheights: process.env.ALAMOHEIGHTS_WIDGET_ID,
  locationUrls: process.env.LOCATION_URLS,
  nextDoorApiKey: process.env.NEXT_DOOR_API_KEY,
};

module.exports = Config;
