import Config from 'app.config';
import { get } from 'lodash-es';
import { call, takeLatest } from 'redux-saga/effects';
import { analytics, sagaErrorHandling } from '../../utils/common';
import request, { buildRequestObj } from '../../utils/request';

function* createKlaviyoSubscription(action) {
  const { email, zip } = action.payload;

  const custom_properties = {
    'Completed Appointments': 0,
    'Upcoming Appointments': 0,
    zip,
  };
  const listId = 'Me8sXZ';

  const requestOptions = buildRequestObj(
    'PUT',
    { custom_properties, email, listId },
    false,
  );
  const requestPath = `${Config.apiBasev2}/user/klaviyo/custom_property`;
  try {
    const result = yield call(request, requestPath, requestOptions);
    if (result && get(result, 'status') !== 400) {
      action.resolve();
    } else {
      yield sagaErrorHandling(
        result,
        action.reject,
        'klaviyo subscription failed',
      );
    }
  } catch (error) {
    analytics('Subscribe Error', null, true);
    yield sagaErrorHandling(
      error,
      action.reject,
      'klaviyo subscription failed',
    );
  }
}

function* watchKlaviyoSubscription() {
  yield takeLatest('SUBMIT_KLAVIYO_SUBSCRIPTION', createKlaviyoSubscription);
}

export default {
  watchKlaviyoSubscription,
};
