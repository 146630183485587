/**
 *
 * PageHeading
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';

function PageHeading(props) {
  const addClass = props.primary ? 'meet-squeeze__hdg' : '';
  //   const headerCurve = !!props.headerCurve;
  return (
    <div>
      {!props.noDisplay && (
        <header className="header-hdr">
          <h1
            className={`header-font own-header ucase hide-phone ${props.addClass ||
              addClass}`}
            title={props.children}
          >
            {props.children}
          </h1>

          <h1
            className={`header-font own-header ucase phone-only ${props.addClass ||
              addClass}`}
            title={props.children}
          >
            {props.mobileTitle}
          </h1>
          {props.additionalMobileContent}
          <div className="curve curve--blue-inner phone-only">
            <svg
              className="phone-only"
              width="100%"
              height="23px"
              viewBox="0 0 375 23"
              preserveAspectRatio="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 0c36.1 13.7 106.528 23 187.5 23S338.9 13.7 375 0H0z"
                fill="#8DD1D8"
                fillRule="evenodd"
              />
            </svg>
          </div>
        </header>
      )}
      {props.noDisplay && (
        <header
          className={props.noDisplay ? 'header-hdr hide-phone' : 'header-hdr'}
        >
          <h1
            className={`header-font own-header ucase hide-phone ${props.addClass ||
              addClass}`}
            title={props.children}
          >
            {props.children}
          </h1>
        </header>
      )}
    </div>
  );
}

PageHeading.propTypes = {
  children: PropTypes.node.isRequired,
  addClass: PropTypes.string,
  primary: PropTypes.bool,
  headerCurve: PropTypes.bool,
  mobileTitle: PropTypes.string,
  noDisplay: PropTypes.string,
};

export default PageHeading;
