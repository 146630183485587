/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import { compose } from 'redux';
import injectSaga from 'utils/injectSaga';

import { LastLocationProvider } from 'react-router-last-location';

import ScrollToTop from '../../components/ScrollToTop';
import EligibleRoute from '../App/EligibleRoute';
import PrivateRoute from '../App/PrivateRoute';
import AppointmentSaga from '../Appointment/saga';

const HomePage = lazy(() => import('../Home'));
const Appointment = lazy(() => import('../Appointment'));
const MyAppointments = lazy(() => import('../Appointment/appointments'));
const { InviteFriendsShare } = lazy(() => import('../InviteFriends/share'));
const MyTherapists = lazy(() => import('../Therapist/my_therapists'));
const OwnSqueeze = lazy(() => import('../../components/OwnSqueeze'));
const Abandonmentpage = lazy(() => import('../Abandonmentpage'));
const ErrorContent = lazy(() => import('../../components/ErrorContent'));
const Philanthropy = lazy(() => import('../../components/Philanthropy'));
const DesktopInstall = lazy(() => import('../../components/DesktopInstall'));
const TermsOfUse = lazy(() => import('../../components/TermsOfUse'));
const PrivacyPolicy = lazy(() => import('../../components/PrivacyPolicy'));
const ChangePassword = lazy(() => import('../ChangePassword'));
const ForgotPassword = lazy(() => import('../ForgotPassword'));
const ForgotPasswordLinkSent = lazy(() =>
  import('../ForgotPassword/password-reset-link-sent'),
);
const ResetPassword = lazy(() => import('../ForgotPassword/reset-password'));
const Therapist = lazy(() => import('../Therapist'));
const HowItWorks = lazy(() => import('../../components/HowItWorks'));
const Gift = lazy(() => import('../Gift'));
const Preferences = lazy(() => import('../Preferences'));
const Membership = lazy(() => import('../Membership'));
const Signup = lazy(() => import('../Signup'));
const Login = lazy(() => import('../Login'));
const Pricing = lazy(() => import('../Pricing'));
const Print = lazy(() => import('../Print'));
const AboutUs = lazy(() => import('../../components/AboutUs'));
const Careers = lazy(() => import('../../components/Careers'));
const Profile = lazy(() => import('../Profile'));
const InviteFriends = lazy(() => import('../InviteFriends'));
const Gratuity = lazy(() => import('../Gratuity-Rating-Review'));

function App() {
  if (localStorage.email) {
    // eslint-disable-next-line no-undef
    branch.setIdentity(localStorage.email);
  }
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <ScrollToTop>
          <div>
            <Suspense fallback={<></>}>
              <Switch>
                <PrivateRoute
                  path="/gratuity"
                  toPath="/login"
                  component={Gratuity}
                />
                <Route path="/appointment" component={Appointment} />
                <Route path="/therapist" component={Therapist} />
                <Route path="/membership" component={Membership} />
                <Route path="/gift" component={Gift} />
                <Route path="/register" component={Signup} />
                <Route path="/login" component={Login} />
                <Route path="/print/:giftId" component={Print} />
                <PrivateRoute
                  path="/preferences"
                  toPath="/login"
                  // eligibleOnly
                  component={Preferences}
                />
                <PrivateRoute
                  path="/appointments"
                  toPath="/login"
                  eligibleOnly
                  component={MyAppointments}
                />
                <PrivateRoute
                  path="/profile"
                  toPath="/login"
                  eligibleOnly
                  component={Profile}
                />
                <PrivateRoute
                  path="/my-therapists"
                  toPath="/login"
                  eligibleOnly
                  component={MyTherapists}
                />
                <Redirect from="/thank-you" to="/#thank-you" />
                <EligibleRoute exact path="/" component={HomePage} />
                <EligibleRoute
                  path="/abandonmentpage"
                  component={Abandonmentpage}
                />
                <EligibleRoute exact path="/pricing" component={Pricing} />
                <EligibleRoute path="/about-us" component={AboutUs} />
                <EligibleRoute path="/franchising" component={OwnSqueeze} />
                <EligibleRoute path="/happy" component={OwnSqueeze} />
                <EligibleRoute path="/earn" component={OwnSqueeze} />
                <EligibleRoute path="/careers" component={Careers} />
                <EligibleRoute path="/how-it-works" component={HowItWorks} />
                <EligibleRoute
                  path="/privacy-policy"
                  component={PrivacyPolicy}
                />
                <EligibleRoute path="/terms-of-use" component={TermsOfUse} />
                <EligibleRoute path="/philanthropy" component={Philanthropy} />
                <Redirect from="/joinus" to="/careers" />
                <EligibleRoute
                  path="/invite/share"
                  component={InviteFriendsShare}
                />
                <Route
                  path="/invite"
                  toPath="/login" // eligibleOnly
                  component={InviteFriends}
                />
                <EligibleRoute path="/install" component={DesktopInstall} />

                <EligibleRoute
                  exact
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <EligibleRoute
                  exact
                  path="/forgot-password/sent"
                  component={ForgotPasswordLinkSent}
                />
                <EligibleRoute
                  path="/password/reset"
                  component={ResetPassword}
                />
                <EligibleRoute
                  exact
                  path="/change-password"
                  component={ChangePassword}
                />
                <Route path="*" component={ErrorContent} />
              </Switch>
            </Suspense>
          </div>
        </ScrollToTop>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

const withSagaCheckGiftCode = injectSaga({
  key: 'checkGiftCode',
  saga: AppointmentSaga.watchCheckGiftCode,
});
export default compose(
  withRouter,
  withSagaCheckGiftCode,
)(App);
