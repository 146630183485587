import { createSelector } from 'reselect';

/**
 * Direct selector to the user state domain
 */
const selectUserDomain = () => state => state.get('user');

const makeSelectUser = () =>
  createSelector(selectUserDomain(), user => user.toJS());

const makeSelectUserData = () =>
  createSelector(selectUserDomain(), user => user.get('userData').toJS());

const makeSelectUserId = () =>
  createSelector(selectUserDomain(), user => user.getIn(['userData', 'id']));

export { makeSelectUser, makeSelectUserData, makeSelectUserId };
