import { fromJS } from 'immutable';

import {
  SET_USER,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  CLEAR_USER,
  FETCH_USER_CARDS,
  FETCH_USER_CARDS_SUCCESS,
  FETCH_USER_CARDS_FAILURE,
  CREATE_USER_CARD,
  CREATE_USER_CARD_SUCCESS,
  CREATE_USER_CARD_FAILURE,
  UPDATE_USER_CARD,
  UPDATE_USER_CARD_SUCCESS,
  UPDATE_USER_CARD_FAILURE,
  CLEAR_USER_CARD,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_USER_TRANSACTIONS,
  FETCH_USER_TRANSACTIONS_SUCCESS,
  FETCH_USER_TRANSACTIONS_FAILURE,
  CHECK_USER_EMAIL,
  CHECK_USER_EMAIL_SUCCESS,
  CHECK_USER_EMAIL_FAILURE,
  ADD_KLAVIYO_MEMBER,
  ADD_KLAVIYO_MEMBER_SUCCESS,
  ADD_KLAVIYO_MEMBER_FAILURE,
} from './constants';

const initialState = fromJS({
  fetching: false,
  error: null,
  userData: {},

  userCards: [],
  fetchingUserCards: false,
  fetchingUserCardsError: null,

  createUserCardResult: {},
  creatingUserCard: false,
  creatingUserCardError: null,

  updateUserCardResult: {},
  updatingUserCard: false,
  updatingUserCardError: null,

  updatingUser: false,
  updatingUserError: null,
  updateUserResult: {},

  fetchingUserTransactions: false,
  fetchingUserTransactionsError: null,
  userTransactions: [],

  userEmailResult: [],
  checkingUserEmail: false,
  checkingUserEmailError: null,

  klaviyoResult: [],
  addingKlaviyoMember: false,
  addingKlaviyoMemberError: null,
});

function userReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_USER:
      return state.withMutations(s => {
        s.set('fetching', true)
          .set('error', null)
          .set('userData', fromJS({}));
      });

    case FETCH_USER_SUCCESS:
      return state.withMutations(s => {
        s.set('fetching', false)
          .set('error', null)
          .set('userData', fromJS(action.userData));
      });

    case FETCH_USER_FAILURE:
      return state.withMutations(s => {
        s.set('fetching', false)
          .set('error', action.error)
          .set('userData', fromJS({}));
      });

    case SET_USER:
      return state.withMutations(s => {
        s.set('userData', fromJS(action.userData));
      });

    case CLEAR_USER:
      return initialState;

    case FETCH_USER_CARDS:
      return state.withMutations(s => {
        s.set('fetchingUserCards', true)
          .set('fetchingUserCardsError', null)
          .set('userCards', fromJS([]));
      });

    case FETCH_USER_CARDS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingUserCards', false)
          .set('fetchingUserCardsError', null)
          .set('userCards', fromJS(action.result));
      });

    case FETCH_USER_CARDS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingUserCards', false)
          .set('fetchingUserCardsError', action.error)
          .set('userCards', fromJS([]));
      });

    case CREATE_USER_CARD:
      return state.withMutations(s => {
        s.set('creatingUserCard', true)
          .set('creatingUserCardError', null)
          .set('createUserCardResult', fromJS({}));
      });

    case CREATE_USER_CARD_SUCCESS:
      return state.withMutations(s => {
        s.set('creatingUserCard', false)
          .set('creatingUserCardError', null)
          .set('createUserCardResult', fromJS(action.result));
      });

    case CREATE_USER_CARD_FAILURE:
      return state.withMutations(s => {
        s.set('creatingUserCard', false)
          .set('creatingUserCardError', action.error)
          .set('createUserCardResult', fromJS({}));
      });

    case UPDATE_USER_CARD:
      return state.withMutations(s => {
        s.set('updatingUserCard', true)
          .set('updatingUserCardError', null)
          .set('updateUserCardResult', fromJS({}));
      });

    case UPDATE_USER_CARD_SUCCESS:
      return state.withMutations(s => {
        s.set('updatingUserCard', false)
          .set('updatingUserCardError', null)
          .set('updateUserCardResult', fromJS(action.result));
      });

    case UPDATE_USER_CARD_FAILURE:
      return state.withMutations(s => {
        s.set('updatingUserCard', false)
          .set('updatingUserCardError', action.error)
          .set('updateUserCardResult', fromJS({}));
      });

    case CLEAR_USER_CARD:
      return state.withMutations(s => {
        s.set('creatingUserCard', false)
          .set('updatingUserCard', false)
          .set('creatingUserCardError', null)
          .set('updatingUserCardError', null)
          .set('createUserCardResult', fromJS({}))
          .set('updateUserCardResult', fromJS({}));
      });

    case UPDATE_USER:
      return state.withMutations(s => {
        s.set('updatingUser', true)
          .set('updatingUserError', null)
          .set('updateUserResult', fromJS({}));
      });

    case UPDATE_USER_SUCCESS:
      return state.withMutations(s => {
        s.set('updatingUser', false)
          .set('updatingUserError', null)
          .set('updateUserResult', fromJS(action.result));
      });

    case UPDATE_USER_FAILURE:
      return state.withMutations(s => {
        s.set('updatingUser', false)
          .set('updatingUserError', action.error)
          .set('updateUserResult', fromJS({}));
      });

    case FETCH_USER_TRANSACTIONS:
      return state.withMutations(s => {
        s.set('fetchingUserTransactions', true)
          .set('fetchingUserTransactionsError', null)
          .set('userTransactions', fromJS([]));
      });

    case FETCH_USER_TRANSACTIONS_SUCCESS:
      return state.withMutations(s => {
        s.set('fetchingUserTransactions', false)
          .set('fetchingUserTransactionsError', null)
          .set('userTransactions', fromJS(action.result));
      });

    case FETCH_USER_TRANSACTIONS_FAILURE:
      return state.withMutations(s => {
        s.set('fetchingUserTransactions', false)
          .set('fetchingUserTransactionsError', action.error)
          .set('userTransactions', fromJS({}));
      });

    case CHECK_USER_EMAIL:
      return state.withMutations(s => {
        s.set('checkingUserEmail', true)
          .set('checkingUserEmailError', null)
          .set('userEmailResult', fromJS({}));
      });

    case CHECK_USER_EMAIL_SUCCESS:
      return state.withMutations(s => {
        s.set('checkingUserEmail', false)
          .set('checkingUserEmailError', null)
          .set('userEmailResult', fromJS(action.result));
      });

    case CHECK_USER_EMAIL_FAILURE:
      return state.withMutations(s => {
        s.set('checkingUserEmail', false)
          .set('checkingUserEmailError', action.error)
          .set('userEmailResult', fromJS({}));
      });

    case ADD_KLAVIYO_MEMBER:
      return state.withMutations(s => {
        s.set('addingKlaviyoMember', true)
          .set('addingKlaviyoMemberError', null)
          .set('klaviyoResult', fromJS({}));
      });

    case ADD_KLAVIYO_MEMBER_SUCCESS:
      return state.withMutations(s => {
        s.set('addingKlaviyoMember', false)
          .set('addingKlaviyoMemberError', null)
          .set('klaviyoResult', fromJS(action.result));
      });

    case ADD_KLAVIYO_MEMBER_FAILURE:
      return state.withMutations(s => {
        s.set('addingKlaviyoMember', false)
          .set('addingKlaviyoMemberError', action.error)
          .set('klaviyoResult', fromJS({}));
      });

    case 'RESET_ACTION':
      return initialState;

    default:
      return state;
  }
}

export default userReducer;
