export const LOGIN_USER = 'app/User/LOGIN_USER';
export const LOGOUT_USER = 'app/User/LOGOUT_USER';

export const SET_USER = 'app/User/SET_USER';
export const CLEAR_USER = 'app/User/CLEAR_USER';

export const FETCH_USER = 'app/User/FETCH_USER';
export const FETCH_USER_SUCCESS = 'app/User/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'app/User/FETCH_USER_FAILURE';

export const SIGNUP_USER = 'app/User/SIGNUP_USER';

export const CHECK_USER_EMAIL = 'app/User/CHECK_USER_EMAIL';
export const CHECK_USER_EMAIL_SUCCESS = 'app/User/CHECK_USER_EMAIL_SUCCESS';
export const CHECK_USER_EMAIL_FAILURE = 'app/User/CHECK_USER_EMAIL_FAILURE';

export const FETCH_USER_CARDS = 'app/User/FETCH_USER_CARDS';
export const FETCH_USER_CARDS_SUCCESS = 'app/User/FETCH_USER_CARDS_SUCCESS';
export const FETCH_USER_CARDS_FAILURE = 'app/User/FETCH_USER_CARDS_FAILURE';

export const CREATE_USER_CARD = 'app/User/CREATE_USER_CARD';
export const CREATE_USER_CARD_SUCCESS = 'app/User/CREATE_USER_CARD_SUCCESS';
export const CREATE_USER_CARD_FAILURE = 'app/User/CREATE_USER_CARD_FAILURE';
export const CLEAR_USER_CARD = 'app/User/CLEAR_USER_CARD';

export const UPDATE_USER_CARD = 'app/User/UPDATE_USER_CARD';
export const UPDATE_USER_CARD_SUCCESS = 'app/User/UPDATE_USER_CARD_SUCCESS';
export const UPDATE_USER_CARD_FAILURE = 'app/User/UPDATE_USER_CARD_FAILURE';

export const FORGOT_PASSWORD_LINK = 'app/User/FORGOT_PASSWORD_LINK';
export const RESET_PASSWORD = 'app/User/RESET_PASSWORD';

export const UPDATE_USER = 'app/User/UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'app/User/UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'app/User/UPDATE_USER_FAILURE';

export const FETCH_USER_TRANSACTIONS = 'app/User/FETCH_USER_TRANSACTIONS';
export const FETCH_USER_TRANSACTIONS_SUCCESS =
  'app/User/FETCH_USER_TRANSACTIONS_SUCCESS';
export const FETCH_USER_TRANSACTIONS_FAILURE =
  'app/User/FETCH_USER_TRANSACTIONS_FAILURE';

export const CHANGE_PASSWORD = 'app/User/CHANGE_PASSWORD';

export const ADD_KLAVIYO_MEMBER = 'app/User/ADD_KLAVIYO_MEMBER';
export const ADD_KLAVIYO_MEMBER_SUCCESS = 'app/User/ADD_KLAVIYO_MEMBER_SUCCESS';
export const ADD_KLAVIYO_MEMBER_FAILURE = 'app/User/ADD_KLAVIYO_MEMBER_FAILURE';
