/* eslint-disable camelcase */
import {
  LOGIN_USER,
  LOGOUT_USER,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  SIGNUP_USER,
  SET_USER,
  CLEAR_USER,
  FETCH_USER_CARDS,
  FETCH_USER_CARDS_SUCCESS,
  FETCH_USER_CARDS_FAILURE,
  CREATE_USER_CARD,
  CREATE_USER_CARD_SUCCESS,
  CREATE_USER_CARD_FAILURE,
  UPDATE_USER_CARD,
  UPDATE_USER_CARD_SUCCESS,
  UPDATE_USER_CARD_FAILURE,
  CLEAR_USER_CARD,
  FORGOT_PASSWORD_LINK,
  RESET_PASSWORD,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  FETCH_USER_TRANSACTIONS,
  FETCH_USER_TRANSACTIONS_SUCCESS,
  FETCH_USER_TRANSACTIONS_FAILURE,
  CHANGE_PASSWORD,
  CHECK_USER_EMAIL,
  CHECK_USER_EMAIL_SUCCESS,
  CHECK_USER_EMAIL_FAILURE,
  ADD_KLAVIYO_MEMBER,
  ADD_KLAVIYO_MEMBER_SUCCESS,
  ADD_KLAVIYO_MEMBER_FAILURE,
} from './constants';

export function loginUser(userData, formProps, history, toPath) {
  return {
    type: LOGIN_USER,
    userData,
    formProps,
    history,
    toPath,
  };
}

export function logoutUser(history) {
  return {
    type: LOGOUT_USER,
    history,
  };
}

export function setUser(userData) {
  const userDataWithoutSessionToken = { ...userData };
  delete userDataWithoutSessionToken.token;
  return {
    type: SET_USER,
    userData: userDataWithoutSessionToken,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

export function fetchUser(history, redirectAfterFetch) {
  return {
    type: FETCH_USER,
    history,
    redirectAfterFetch,
  };
}

export function fetchUserSuccess(userData) {
  const userDataWithoutSessionToken = {
    ...userData,
  };
  delete userDataWithoutSessionToken.token;
  return {
    type: FETCH_USER_SUCCESS,
    userData,
  };
}

export function fetchUserFailure(error) {
  return {
    type: FETCH_USER_FAILURE,
    error,
  };
}

export function signupUser(userData, formProps, history, toPath) {
  return {
    type: SIGNUP_USER,
    userData,
    formProps,
    history,
    toPath,
  };
}

export function fetchUserCards() {
  return {
    type: FETCH_USER_CARDS,
  };
}

export function fetchUserCardsSuccess(result) {
  return {
    type: FETCH_USER_CARDS_SUCCESS,
    result,
  };
}

export function fetchUserCardsFailure(error) {
  return {
    type: FETCH_USER_CARDS_FAILURE,
    error,
  };
}

export function createUserCard(stripe_token) {
  return {
    type: CREATE_USER_CARD,
    stripe_token,
  };
}

export function createUserCardSuccess(result) {
  return {
    type: CREATE_USER_CARD_SUCCESS,
    result,
  };
}

export function createUserCardFailure(error) {
  return {
    type: CREATE_USER_CARD_FAILURE,
    error,
  };
}

export function updateUserCard(card_id) {
  return {
    type: UPDATE_USER_CARD,
    card_id,
  };
}

export function updateUserCardSuccess(result) {
  return {
    type: UPDATE_USER_CARD_SUCCESS,
    result,
  };
}

export function updateUserCardFailure(error) {
  return {
    type: UPDATE_USER_CARD_FAILURE,
    error,
  };
}

export function clearUserCard() {
  return {
    type: CLEAR_USER_CARD,
  };
}

export function forgotPassword(email, toPath, routeState) {
  return {
    type: FORGOT_PASSWORD_LINK,
    email,
    toPath,
    routeState,
  };
}

export function resetPassword({
  password,
  token,
  toPath,
  password_confirmation,
  onSuccess,
}) {
  return {
    type: RESET_PASSWORD,
    password,
    token,
    toPath,
    password_confirmation,
    onSuccess,
  };
}

export function updateUser(userData) {
  return {
    type: UPDATE_USER,
    userData,
  };
}

export function updateUserSuccess(result) {
  return {
    type: UPDATE_USER_SUCCESS,
    result,
  };
}
export function updateUserFailure(error) {
  return {
    type: UPDATE_USER_FAILURE,
    error,
  };
}

export function fetchUserTransactions() {
  return {
    type: FETCH_USER_TRANSACTIONS,
  };
}

export function fetchUserTransactionsSuccess(result) {
  return {
    type: FETCH_USER_TRANSACTIONS_SUCCESS,
    result,
  };
}
export function fetchUserTransactionsFailure(error) {
  return {
    type: FETCH_USER_TRANSACTIONS_FAILURE,
    error,
  };
}

export function changePassword({ password, password_confirmation, onSuccess }) {
  return {
    type: CHANGE_PASSWORD,
    password,
    password_confirmation,
    onSuccess,
  };
}

export function checkUserEmail(email) {
  return {
    type: CHECK_USER_EMAIL,
    email,
  };
}

export function checkUserEmailSuccess(result) {
  return {
    type: CHECK_USER_EMAIL_SUCCESS,
    result,
  };
}

export function checkUserEmailFailure(error) {
  return {
    type: CHECK_USER_EMAIL_FAILURE,
    error,
  };
}

export function addKlaviyoMember(payload) {
  return {
    type: ADD_KLAVIYO_MEMBER,
    payload,
  };
}

export function addKlaviyoMemberSuccess(result) {
  return {
    type: ADD_KLAVIYO_MEMBER_SUCCESS,
    result,
  };
}

export function addKlaviyoMemberFailure(error) {
  return {
    type: ADD_KLAVIYO_MEMBER_FAILURE,
    error,
  };
}
