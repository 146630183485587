/* eslint-disable camelcase */
/*
 *
 * Membership actions
 *
 */

import {
  FETCH_MEMBERSHIP_DETAILS,
  FETCH_MEMBERSHIP_DETAILS_SUCCESS,
  FETCH_MEMBERSHIP_DETAILS_FAILURE,
  SET_SELECTED_MEMBERSHIP_TYPE,
  PURCHASE_MEMBERSHIP,
  PURCHASE_MEMBERSHIP_SUCCESS,
  PURCHASE_MEMBERSHIP_FAILURE,
  CANCEL_MEMBERSHIP,
  CANCEL_MEMBERSHIP_SUCCESS,
  CANCEL_MEMBERSHIP_FAILURE,
  PAUSE_MEMBERSHIP,
  PAUSE_MEMBERSHIP_SUCCESS,
  PAUSE_MEMBERSHIP_FAILURE,
  RESUME_MEMBERSHIP,
  RESUME_MEMBERSHIP_SUCCESS,
  RESUME_MEMBERSHIP_FAILURE,
  CLEAR_MEMBERSHIP_PURCHASE_DATA,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS,
  SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE,
  CLEAR_MEMBERSHIP_DATA,
  CLEAR_MEMBERSHIP_ERROR,
  SET_HOME_LOCATION,
  CLEAR_HOME_LOCATION,
} from './constants';

export function fetchMembershipDetails(membershipId) {
  return {
    type: FETCH_MEMBERSHIP_DETAILS,
    membershipId,
  };
}

export function fetchMembershipDetailsSuccess(result) {
  return {
    type: FETCH_MEMBERSHIP_DETAILS_SUCCESS,
    result,
  };
}

export function fetchMembershipDetailsFailure(error) {
  return {
    type: FETCH_MEMBERSHIP_DETAILS_FAILURE,
    error,
  };
}

export function setSelectedMembershipType(selectedMembershipType) {
  return {
    type: SET_SELECTED_MEMBERSHIP_TYPE,
    selectedMembershipType,
  };
}

export function purchaseMembership({ action, plan, coupon, location_id }) {
  return {
    type: PURCHASE_MEMBERSHIP,
    action,
    plan,
    coupon,
    location_id,
  };
}

export function setHomeLocation(location_id) {
  return {
    type: SET_HOME_LOCATION,
    location_id,
  };
}

export function clearHomeLocation() {
  return {
    type: CLEAR_HOME_LOCATION,
  };
}

export function purchaseMembershipSuccess(result) {
  return {
    type: PURCHASE_MEMBERSHIP_SUCCESS,
    result,
  };
}

export function purchaseMembershipFailure(error) {
  return {
    type: PURCHASE_MEMBERSHIP_FAILURE,
    error,
  };
}

export function cancelMembership() {
  return {
    type: CANCEL_MEMBERSHIP,
  };
}

export function cancelMembershipSuccess(result) {
  return {
    type: CANCEL_MEMBERSHIP_SUCCESS,
    result,
  };
}

export function cancelMembershipFailure(error) {
  return {
    type: CANCEL_MEMBERSHIP_FAILURE,
    error,
  };
}

export function pauseMembership(
  isUpdatePausedDate,
  resume_pause_date,
  membership_id,
) {
  return {
    type: PAUSE_MEMBERSHIP,
    isUpdatePausedDate,
    resume_pause_date,
    membership_id,
  };
}

export function pauseMembershipSuccess(result) {
  return {
    type: PAUSE_MEMBERSHIP_SUCCESS,
    result,
  };
}

export function pauseMembershipFailure(error) {
  return {
    type: PAUSE_MEMBERSHIP_FAILURE,
    error,
  };
}

export function resumeMembership() {
  return {
    type: RESUME_MEMBERSHIP,
  };
}

export function resumeMembershipSuccess(result) {
  return {
    type: RESUME_MEMBERSHIP_SUCCESS,
    result,
  };
}

export function resumeMembershipFailure(error) {
  return {
    type: RESUME_MEMBERSHIP_FAILURE,
    error,
  };
}

export function clearMembershipPurchaseData() {
  return {
    type: CLEAR_MEMBERSHIP_PURCHASE_DATA,
  };
}

export function submitMembershipCancellationReasons(reason) {
  return {
    type: SUBMIT_MEMBERSHIP_CANCELLATION_REASONS,
    reason,
  };
}

export function submitMembershipCancellationReasonsSuccess(result) {
  return {
    type: SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_SUCCESS,
    result,
  };
}

export function submitMembershipCancellationReasonsFailure(error) {
  return {
    type: SUBMIT_MEMBERSHIP_CANCELLATION_REASONS_FAILURE,
    error,
  };
}

export function clearMembershipData() {
  return {
    type: CLEAR_MEMBERSHIP_DATA,
  };
}

export function clearMembershipError() {
  return {
    type: CLEAR_MEMBERSHIP_ERROR,
  };
}
