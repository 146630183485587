import Config from 'app.config';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import {
  flatten,
  get,
  isEmpty,
  map,
  reverse,
  some,
  startsWith,
  values,
} from 'lodash-es';
import { SubmissionError } from 'redux-form/immutable';
import { call, put } from 'redux-saga/effects';
import request from 'utils/request';

const APPOINTMENT_CHARGE_WINDOW = 12;

const genderMap = {
  female: 1,
  male: 0,
};

const genderMapValues = {
  1: 'female',
  0: 'male',
};

const openMap = (lat, lng, opts = '_blank') =>
  window.open(mapLink(lat, lng), opts);

const giftTypeMap = {
  single: 'Massage',
  monthly: 'Membership',
  bucks: 'Squeeze Bucks',
};

const membershipEntityMap = {
  1: 'active',
  2: 'paused',
  3: 'cancelled',
  4: 'gift',
  5: 'expired',
};

const giftSubTypeMap = {
  single: {
    mini: 'Mini Massage',
    mid: 'Mid Massage',
    main: 'Main Massage',
  },
  monthly: {
    mid: 'Mid Member',
    main: 'Main Member',
  },
};

// Note that date corresponds to string in the mentioned dateFormat
const timeDiff = date => {
  const zone = 'America/Los_Angeles';
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const now = dayjsDateParserUtiliy(new Date(), zone, dateFormat);
  const d = date && dayjsDateParserUtiliy(date, zone, dateFormat);
  const diff = d && new Date(d) - new Date(now);
  return diff;
};

const setBranchDisountCode = data => {
  localStorage.setItem(
    'userDiscount',
    JSON.stringify({
      source: 'branch',
      discountCode: data.data_parsed.discount,
      discountValue: 20,
      createdAt: data.data_parsed.createdAt,
      referring_link: data.data_parsed['~referring_link'],
    }),
  );
};

const getBranchDiscountCode = () => {
  const userDiscount = localStorage.getItem('userDiscount');
  return userDiscount ? JSON.parse(userDiscount) : null;
};

const numberOfLines = str => str.split(/\r\n|\r|\n/).length;
const restrictNumOfLines = (event, maxLines) => {
  const numOflines = numberOfLines(event.target.value);
  const ary = event.target.value.split(/\r\n|\r|\n/);
  if (numOflines > maxLines) {
    const finalVal = ary
      .slice(0, maxLines)
      .filter(s => s.length > 0)
      .join('\n');
    // eslint-disable-next-line
    event.target.value = finalVal;
    return false;
  }
  return true;
};

const analytics = (page, opts, track = false) => {
  if (!track) {
    window.analytics.page(page, opts);
  } else {
    window.analytics.track(page, opts);
  }
};
const segmentIdentify = detail => {
  // preemtpive measure in case malformed json
  try {
    // Not sure when `detail` changed from string to `object`. Applying check conditionally still.
    const json =
      detail && typeof detail === 'object' ? detail : JSON.parse(detail);
    if (localStorage.getItem('applicationState')) {
      if (json.id) {
        const userDetail = {
          email: json.email,
          phone: `+1${json.phone}`,
          name: `${json.first_name} ${json.last_name}`,
          PP: `https://admin.squeezemassage.com/#/guest/${detail.id}`,
          'Home Location': detail.location ? detail.location.name : null,
        };
        window.analytics.identify(json.email, userDetail);
      }
    }
  } catch (error) {
    window.console.error('parse err');
  }
};

const getGiftSegmentData = giftData => {
  let qty = 0;
  if (giftData.selectedGiftType === 'bucks') {
    qty = giftData.amount;
  } else {
    qty = giftData.selectedGiftSubTypeQuantity || giftData.membershipDuration;
  }
  const giftType = giftTypeMap[giftData.selectedGiftType];
  const giftSubtype =
    giftData.selectedGiftType !== 'bucks'
      ? giftSubTypeMap[giftData.selectedGiftType][giftData.selectedGiftSubType]
      : null;
  const segmentData = {
    Category: 'Gifting',
    'Gift Type': giftType,
    'Gift Sub-Type': giftSubtype,
    QTY: qty,
  };
  if (giftData.netTotal) segmentData.value = giftData.netTotal;
  if (giftData.shippingDetails && giftData.shippingDetails.shipping_type) {
    segmentData['Shipping Option'] = titleCase(
      giftData.shippingDetails.shipping_type,
    );
  }
  return segmentData;
};

const titleCase = text =>
  text.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );

const onFormSubmitPromise = (dispatch, actionObj, history) =>
  new Promise((resolve, reject) => {
    dispatch({ resolve, reject, ...actionObj, history });
  }).catch(error => {
    throw new SubmissionError({ _error: error });
  });

const isMobile = () => window.innerWidth <= 500;

const sagaErrorHandling = (error, reject, errorMsg) => {
  if (reject) {
    const err = errorHandling(error);
    if (err) {
      reject(err);
    }
    reject(errorMsg);
  }
};

const errorHandling = error => {
  if (error.response) {
    if (!isEmpty(get(error.response, 'data.errors'))) {
      return flatten(values(get(error.response, 'data.errors')));
    }
    return error.response.data.message;
  } else if (error.message) {
    return error.message;
  }
  return null;
};

const mapLink = (lat, lng, address) => {
  if (!address) return `//maps.google.com?q=${lat},${lng}`;
  return `//maps.google.com?q=${address}`;
};

function* yieldErrorHandling(error, func, defaultMsg) {
  const err = errorHandling(error);
  if (err) {
    yield put(func(err));
  } else {
    yield put(func(defaultMsg));
  }
}

function* defaultAfterCallLogic(result, action) {
  action.resolve();
}

function* commonSagaLogic(
  action,
  requestOptions,
  requestPath,
  failMsg,
  afterCallLogic = defaultAfterCallLogic,
) {
  const { reject } = action;
  let requestURL;
  if (requestPath.fullUrl) {
    requestURL = requestPath.fullUrl;
  } else {
    requestURL = `${Config.apiBasev2}${requestPath}`;
  }

  try {
    const result = yield call(request, requestURL, requestOptions);
    yield afterCallLogic(result, action);
  } catch (error) {
    sagaErrorHandling(error, reject, failMsg);
  }
}

function getQueryVariable(qsparam, query) {
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === qsparam) {
      return decodeURIComponent(pair[1]);
    }
  }
  return '';
}

function slugGenerator(locationName) {
  return (
    locationName &&
    locationName
      .toLowerCase()
      .split(' ')
      .join('-')
  );
}

const storeTimings = {
  storeOpenTime: '08:00:00',
  storeClosingTime: '22:00:00',
};

const getUserMembershipType = plan => (startsWith(plan, '50') ? 'mid' : 'main');

const formatPricing = (services, membershipDetails) => {
  const isFoundingMembership =
    membershipDetails && membershipDetails.campaign_id;
  if (!isEmpty(services)) {
    return map(reverse(services), serv => ({
      membership: `${serv.title}`,
      duration: serv.duration,
      price: isFoundingMembership
        ? serv.discounted_subscription_price
        : serv.subscription_price,
      oneTimePrice: serv.non_member_price,
      info: `One ${serv.duration}-min table massage ($${
        serv.non_member_price
      } value) per month, plus perks.`,
    }));
  }
  return [
    {
      membership: 'mid',
      price: 89,
      duration: 50,
      oneTimePrice: 109,
      info: 'One 50-min table massage ($109 value) per month, plus perks.',
      mobileInfo: '50-min massage',
    },
    {
      membership: 'main',
      price: 119,
      duration: 80,
      oneTimePrice: 139,
      info: 'One 80-min table massage ($139 value) per month, plus perks.',
      mobileInfo: '80-min massage',
    },
  ];
};

const getTherapistName = (therapist, type = 'first_name') =>
  get(therapist, type);

const validateSlotSelection = ({ userData, slot, myAppointments }) => {
  if (isEmpty(userData) || isEmpty(myAppointments)) {
    return true;
  }
  const slotTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  const selectedSlotTime = dayjsDateParserUtiliy(
    get(slot, 'slot_time'),
    get(slot, 'location.timezone'),
    slotTimeFormat,
  );
  const currentAppointmentTimes = map(myAppointments, appt => ({
    start_at: appt.start_at,
    duration: appt.service.duration,
  }));
  // eslint-disable-next-line no-underscore-dangle
  const _slots = [];
  currentAppointmentTimes.forEach(v => {
    _slots.push(v);
  });
  currentAppointmentTimes.forEach(value => {
    for (let i = 15; i < value.duration; i += 15) {
      _slots.push({
        start_at: dayjs(value.start_at)
          .add(i, 'm')
          .format(slotTimeFormat),
        duration: value.duration,
      });
    }
  });
  const isSlotInvalid = some(_slots, time =>
    dayjs(time.start_at).isSame(selectedSlotTime, 'minute'),
  );
  return !isSlotInvalid;
};

const locationDescriptions = {
  'Studio City':
    'Our Studio City location is located on the south side of Ventura Blvd between Laurelgrove Ave and Rhodes Ave. We’re next door to Bonjour Fete, two doors down from MOD nails, and across from The Village. Squeeze Studio City has 12 gorgeous service rooms and offers a high-end experience at an affordable price. Your choice of heat, deep tissue and aromatherapy are all included in the price of your massage, and we also have a pretty sweet app that makes scheduling, paying, tipping, and rating super easy. ',
  Dallas:
    'Our Dallas location is located on the south side of Ventura Blvd between Laurelgrove Ave and Rhodes Ave. We’re next door to Bonjour Fete, two doors down from MOD nails, and across from The Village. Squeeze Dallas has 12 gorgeous service rooms and offers a high-end experience at an affordable price. Your choice of heat, deep tissue and aromatherapy are all included in the price of your massage, and we also have a pretty sweet app that makes scheduling, paying, tipping, and rating super easy. ',
  Bethesda:
    'Our Bethesda location is located on the south side of Ventura Blvd between Laurelgrove Ave and Rhodes Ave. We’re next door to Bonjour Fete, two doors down from MOD nails, and across from The Village. Squeeze Bethesda has 12 gorgeous service rooms and offers a high-end experience at an affordable price. Your choice of heat, deep tissue and aromatherapy are all included in the price of your massage, and we also have a pretty sweet app that makes scheduling, paying, tipping, and rating super easy. ',
};

const SERVICES_TITLE = {
  MAIN: 'main',
  MID: 'mid',
};

const MembershipCancelledStatusId = 3;

const ACTIVEMEMBERSHIP = 1;

const getPausedDate = date => {
  if (date) {
    // eslint-disable-next-line no-underscore-dangle
    const _date = dayjs(
      new Date(`${date.replace(/-/g, '/')} UTC`).toString(),
    ).add(1, 'days');
    return _date;
  }
  return null;
};

const PHASES = ['phase1', 'phase2', 'phase3', 'phase4'];

const FRANCHISE_PHASES = {
  PHASE1: 'phase1',
  PHASE2: 'phase2',
  PHASE3: 'phase3',
  PHASE4: 'phase4',
};

const MEMBERSHIP_TITLES = {
  main: 'Main Membership',
  mid: 'Mid Membership',
};

// eslint-disable-next-line consistent-return
const MembershipService = service => {
  if (!isEmpty(service)) {
    return {
      membership: `${service.title}`,
      duration: service.duration,
      price: service.discounted_subscription_price,
      oneTimePrice: service.subscription_price,
    };
  }
};

// eslint-disable-next-line camelcase
const FoundingMembership_Description =
  'By clicking Become a Founding Member, I agree to the Privacy Policy, Terms, and Membership Terms. I authorize Squeeze to charge my credit card monthly at the rate of the membership selected. Founding Member Special promotional rate is eligible for such time as your membership remains active and good standing. If your membership is canceled or paused, you will no longer be eligible for the Founding Member Special promotional rate, and pricing will be adjusted to the then-current membership rate. The Founding Member Special promotional rate is subject to our Terms of Use in all respects, including but not limited to our reserving the right to terminate your membership for unsatisfactory or untimely payment.';

const slidesCount = 15;

const getMassagePrices = () => {
  const prices = {
    mid: {
      wMembership: 89,
      woMembership: 109,
    },
    main: {
      wMembership: 119,
      woMembership: 139,
    },
  };

  // Fetch the massage prices
  fetch(`${Config.apiBasev2}/location/1/services`)
    .then(res => res.text())
    .then(data => {
      if (data) {
        // eslint-disable-next-line prefer-destructuring
        const services = JSON.parse(data).services;
        // Main massage
        prices.main.wMembership = services[0].member_price;
        prices.main.woMembership = services[0].non_member_price;
        // Mid massage
        prices.mid.wMembership = services[1].member_price;
        prices.mid.woMembership = services[1].non_member_price;
      }
    });

  return prices;
};

const getStyles = () => {
  const styles = [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#f5f5f5',
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#bdbdbd',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#ffffff',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#757575',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#dadada',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#616161',
        },
      ],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [
        {
          color: '#e5e5e5',
        },
      ],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#eeeeee',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#c9c9c9',
        },
      ],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#9e9e9e',
        },
      ],
    },
  ];

  return styles;
};

const dayjsDateParserUtiliy = (time, zone, pattern) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const utcDate = dayjs.utc(time).toString();
  const tzDate = dayjs(utcDate)
    .tz(zone)
    .format(pattern);
  return tzDate;
};

const dayjsLocalDateParserUtiliy = (time, pattern) => {
  if (time === undefined || time === 'Invalid Date') {
    return '';
  }
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const utcDate = dayjs.utc(time).toString();
  const localTzDate = dayjs(utcDate)
    .local()
    .format(pattern);
  return localTzDate;
};

export {
  getStyles,
  dayjsLocalDateParserUtiliy,
  dayjsDateParserUtiliy,
  membershipEntityMap,
  commonSagaLogic,
  sagaErrorHandling,
  onFormSubmitPromise,
  isMobile,
  errorHandling,
  yieldErrorHandling,
  getQueryVariable,
  analytics,
  numberOfLines,
  restrictNumOfLines,
  titleCase,
  getGiftSegmentData,
  segmentIdentify,
  openMap,
  mapLink,
  timeDiff,
  storeTimings,
  getUserMembershipType,
  formatPricing,
  setBranchDisountCode,
  getBranchDiscountCode,
  getTherapistName,
  validateSlotSelection,
  locationDescriptions,
  slugGenerator,
  APPOINTMENT_CHARGE_WINDOW,
  SERVICES_TITLE,
  genderMap,
  genderMapValues,
  MembershipCancelledStatusId,
  getPausedDate,
  PHASES,
  FRANCHISE_PHASES,
  MEMBERSHIP_TITLES,
  MembershipService,
  // eslint-disable-next-line camelcase
  FoundingMembership_Description,
  slidesCount,
  ACTIVEMEMBERSHIP,
  getMassagePrices,
};
